.open-post-size-controller{
    display: block;
    margin: 0px;
    padding: 0px;
}
.profile-page-content-space-manager{
    display: block;
    width: auto;
    margin: 0px;
}
.profile-page-main-container{
    width: 100%;
    background-color: #eee; 
    top: 0;
    margin: 0px;
}
.profile-page-aligner-left-right{
    display: block;
}
.page-title-name{ 
    text-align: center;
    background-color: #333;
    margin: 0px;
    padding: 15px;
    font-size: 25px;
    color: #fff;
    text-decoration: underline;
}
.schedule-page-title-name{ 
    text-align: center;
    background-color: #fff;
    margin: 0px;
    padding: 15px;
    font-size: 25px;
    color: #fff;
    text-decoration: underline;
}
/*
.profile-page-title-name{ 
    text-align: center;
    background-color: #333;
    margin: 0px;
    padding: 15px;
}
*/
.vertical-list {
    list-style-type: none;
    padding: 0;
    margin: 0px;
}
  
.vertical-list li {
    display: block;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    min-height: auto;
}
.posts-interactions{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 10px;
}
.user-profile-posts-interactions{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.user-dashboard-posts-interactions{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 10px;
}
.dashboard-role-statement{
    margin: 0px 0px 5px 0px;
    font-size: 14px;
    font-weight: bold;
    color: #000099;
}
.filter-buttons{
    margin: 0px 10px;
    display: flex;
    justify-content: space-around;
}
.filter-buttons button{
    background-color: none;
    border: none;
}
.filter-buttons button:active{
    background-color: none;
    border: none;
}
.user-profile-posts-interactions .interaction-link-button{
    font-size: small;
}
.user-dashboard-posts-interactions .interaction-link-button{
    font-size: 70%;
}
.posts-interactions .interaction-link-button{
    font-size: small;
}
.interaction-link-button {
    text-decoration: none;
    background-color: #7d7d7d;
    color: white;
    padding: 5px 5px;
    border-radius: 5px;
    width: 33.3%;
    text-align: center;
    margin: 0px 5px;
    text-decoration: none; 
    border: none;
}
  
.interaction-link-button:hover {
    background-color: #000099;
    text-decoration: none;
}

.interaction-link-button .fontawesome-icon{
    padding-left: 5px;
    padding-top: 5px;
}

.interaction-link-button p{
    margin: 0px;
    margin-left: 5px;
}
.interaction-link-support-div{
    display: flex;
    justify-content: center;
}
.more-toggle-button-container{
    width: auto;
    display: flex;
    justify-content: center;
}
.more-toggle-button{
    display: block;
    background-color: #333;
    border: none;
    padding: 10px;
    color: #fff;
    border: 2px solid #474747;
    border-radius: 10px;
}
.filter-and-search-box{
    display: flex;
    margin: 0px 0px 15px 0px;
}
.user-profile-account-posts{
    padding: 0px;
}
.filter-dropdown{
    margin: 0px 10px; 
    position: relative;
} 
.filter-toggle-button {
    background-color: #000099;
    color: white;
    padding: 10px 16px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.5s ease;
}
.filter-toggle-button:hover {
    background-color: #0056b3;
}
  
.filter-options {
    position: absolute;
    left: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 4px;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    display: flex;
    justify-content: space-around;
}
  
.filter-options.show {
    max-height: 200px; /* Adjust height as needed */
    overflow-y: auto; /* Enable scrolling if needed */
}
.filter-options button{
    margin: 10px 5px;
    background-color: #fff;
    border: none;
}
.dashboard-search-input{
    padding: 10px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
}
.main-post-details{
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
}
.profile-short-details-texts{
    margin: 0px;
}
.profile-main-post-details{
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
}
.created-at{
    border-bottom: 2px solid #9A9A9A;
    margin: 0px;
    padding-bottom: 10px;
    color: #9A9A9A;
}
.author-details-in-posts{
    display: flex;
    padding-top: 7px;
}
.author-details-in-posts p{
    margin: 3px 0px 0px 0px;
    font-size: 90%;
}
.posts-accountName{
    padding-left: 5px;
    opacity: 75%;
}
.post-content-text{
    min-height: 150px;
}
.dashboard-post-content-text{
    min-height: 150px;
    margin: 5px 0px 10px 0px; 
}
.user-post-violation{
    min-height: 150px;
}
.post-author-main-block{
    display: flex;
}
.posts-profileimage{
    width: 40px;
    height: 40px;
    border-radius: 30px;
    margin-right: 10px;
}
.posts-author-name-surname{
    font-weight: 600;
}
.post-link{
    color: #242526;
}
.post-link:hover{
    text-decoration: none;
}
.interaction-link-button-comment {
    text-decoration: none;
    background-color: #000099;
    color: white;
    padding: 5px 10px 0px 10px;
    border-radius: 5px;
    width: 33.3%;
    text-align: center;
    margin: 0px 7px;
    text-decoration: none; 
}
.comments-section-open-post{
    background-color: #9A9A9A;
    width: 100%;
    height: auto;
}
.interaction-link-button-comment:hover {
    text-decoration: none;
    background-color: #000099;
}
.user-account-link{
    color: #242526; 
}
.user-account-link:hover{
    text-decoration: none;
}

.single-post-main-block{
    display: flex;
}

.mainly-made-for-delete-container{
    display: flex;
    /*padding-bottom: 20px;*/
    justify-content: right;
    position: absolute;
    margin-right: 5px;
    margin-left: 5px;
    right: 60px;
}
.delete-trash-icon{ 
    display: flex;
    right: 10px;
    /*position: absolute;*/
    /*background-color: #9A9A9A;*/
    border-radius: 30px;
}
.open-post-interaction-link-button {
    text-decoration: none;
    background-color: #9A9A9A;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px 5px 0px ;
    width: 33.3%;
    text-align: center;
    margin: 0px 7px;
    text-decoration: none; 
}
.open-post-interaction-link-button:hover{
    text-decoration: none;
    background-color: #7D7D7D;

}
.open-post-interaction-link-button .fontawesome-icon{
    padding-left: 5px;
    padding-top: 5px;
}
.comments-section{
    width: 100%;
    display: flex;
}
.comments-f-box{
    background-color: #9A9A9A;
    /*margin: 0px 7px;*/
    width: auto;
    border-radius: 0px;
    padding: 10px 12px 10px 10px;
}
.comments-f-box p{
    text-decoration: underline;
}
.open-posts-interactions{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.open-posts-interactions .open-post-interaction-link-button{
    border: none;
    border-radius: 5px 5px 0px 0px;
    font-size: small;
}
.open-posts-interactions .interaction-link-button{
    border: none;
    border-radius: 5px 5px 0px 0px;
    font-size: small;
}
.comment-textbox-field{
    width: 96.7%;
    min-height: 60px;
    padding: 10px;
    border-radius: 5px;
    max-width: 97%;
}
.submit-comment-button{
    padding: 10px;
    border-radius: 7px;
    margin-top: 5px;
    background-color: #000099;
    color: #fff;
    border: none;
}
.comments-list{
    background-color: #9A9A9A;
    padding: 10px;
    border-radius: 0px;
}
.comments{
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.comment-author-details{
    margin: 0px;
}
.comment-author-link{
    color: #242526;
    display: flex;
}
.comment-author-link:hover{
    text-decoration: none;
}
.comment-author-details-account-name{
    margin: 0px;
    color: #9A9A9A;
    padding-left: 5px;
}
.comment-body{
    word-wrap: break-word;
}
.comment-time-stamp{
    color: #9A9A9A;
    font-size: small;
    margin: 0px;
}
.comment-author-profile-image{
    width: 30px;
    border-radius: 30px;
    margin-right: 5px;
}
.no-comments-notification{
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
}
.post-author-access-control{
    display: flex;
    position: relative;
}
.comment-control-button{
    position: absolute;
    right: 5px;
    padding-top: 3px;
}
.dropdown-menu {
    position: absolute;
    top: 100%; /* Position it below the ellipsis icon */
    right: 0;
    z-index: 1000;
    min-width: 180px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 4px;
    background-color: #ffffff;
}
  
.dropdown-menu button {
    width: 100%;
    padding: 8px 12px;
    text-align: left;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.dropdown-menu button:hover {
    background-color: #f0f0f0;
}
.mainly-made-for-edit-container{
    display: flex;
    /*padding-bottom: 20px;*/
    justify-content: right;
    position: absolute;
    margin-right: 5px;
    margin-left: 5px;
    right: 10px;
}
.edit-post-icon{ 
    display: flex;
    background-color: #9A9A9A;
    border-radius: 30px;
}
.change-post-button{
    display: flex;
    right: 10px;
    margin-top: 10px;
}
.change-post-button p{
    margin: 0px;
    color: #fff;
}
.edit-post-modal-open{
    min-width: 400px;
    min-height: 250px;
    border-radius: 10px;
    padding: 10px;
    font-size: large;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.user-container-main-box{
    display: flex;
}
.accounts-user-details{
    background-color: #fff;
}
.account-name-surname{
    font-weight: bold;
}
.add-connection-button{
    padding: 10px;
    margin-left: 10px;
    border-radius: 5px;
}
.active-user-button{
    padding: 10px;
    margin-left: 10px;
    border-radius: 5px;
}
.fontawesome-icon-author-pad .fontawesome-icon{
    background-color: #9a9a9a;
    padding: 10px;
    border-radius: 30px;
}
.fontawesome-post-edit-author-pad .fontawesome-icon{
    background-color: #9a9a9a;
    padding: 10px;
    border-radius: 30px;
}
.details-about-theuser-box{
    min-width: 170px;
}
.details-about-theuser-box p{
    margin: 0px 0px 5px 0px;
}



.profile-navigation-p-color-back{
    color: #000;
    margin-top:10px ;
    margin-bottom:10px ;
    border-radius: 5px;
}
.schedule-profile-navigation-p-color-back{
    color: #000;
    margin-top:10px ;
    margin-bottom:10px ;
    border-radius: 5px;
}
.profile-navigation-p-color-back:hover{
    background-color: #000099;
    text-decoration: none;
    color: #fff;
}
.schedule-profile-navigation-p-color-back:hover{
    background-color: #000099;
    text-decoration: none;
    color: #fff;
}
.profile-navigation-p-color-back-active{
    color: #fff;
    background-color: #000099;
    margin-top:10px ;
    margin-bottom:10px ;
    border-radius: 5px;
}
.schedule-profile-navigation-p-color-back-active{
    color: #fff;
    background-color: #000099;
    margin-top:10px ;
    margin-bottom:10px ;
    border-radius: 5px;
}
.profile-navigation-p-color-back-active:hover{
    background-color: #000099;
    text-decoration: none;
    color: #fff;
}
.schedule-profile-navigation-p-color-back-active:hover{
    background-color: #000099;
    text-decoration: none;
    color: #fff;
}
.profile-navigation{ 
    justify-content: space-evenly;
    display: flex;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.schedule-profile-navigation-container{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
}
.schedule-profile-navigation-container h1{
    color: #242526;
    font-size: 25px;
    text-decoration: underline;
}
.schedule-profile-navigation{ 
    justify-content: space-evenly;
    display: flex;
    
}
.calendar-content-page{
    padding: 0px 10px 10px 10px;
}
.profile-navigation-div{
    width: 70px; 
    text-align: center;
    padding: 7px 0px;
    border-radius: 5px;
}
.schedule-profile-navigation-div{
    width: 70px; 
    text-align: center;
    padding: 7px 0px;
    border-radius: 5px;
}
.profile-navigation-institution{
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;
    border-top: 2px solid #eee;
}
.profile-section-navigation-institution{
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0px;
    margin: 0px 10px; 
    border-top: 2px solid #eee;
}
.profile-navigation-institution-p-color-back{
    padding:  7px 10px 10px 10px;
    border-radius: 7px;
    color: #fff;
    text-decoration: none !important;
}
.profile-navigation-institution-p-color-back:hover{
    border-bottom: 2px solid #fff;
}
.profile-navigation-institution-p-color-back-active{
    border-bottom: 2px solid #fff;
    padding:  7px 10px 10px 10px;
    border-radius: 7px;
    text-decoration: none !important;
}


.section-name-h2-tag{
    text-align: center;
    font-size: 18px;
}
.user-link-sections-blocks{
    padding: 0px 10px 10px 10px; 
    background-color: #eee;
}
.posts-na{
    border: 2px solid #d3d3d3;
    border-radius: 5px;
    padding: 10px;
}
.profile-page-content-container{
    margin-top: 10px;
}
.profile-page-top-section{
    top: 0;
    width: 100%;
    /*background-color: #000099;*/
    background-color: #333;
    border-radius: 0px 0px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.profile-page-top-section h1{
    font-size: 25px;
    text-decoration: underline;
    color: #fff;
}
.profile-image-aligner{
    display: block;
    display: flex;
    justify-content: center;
}
.profile-image-aligner img{
    width: 170px;
    height: 170px; 
    background-color: yellow;
    border-radius: 50%;
    border: 5px solid #fff;
}
.profile-deco-links-container{
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}
.profile-deco-links-link-tag{
    display: block;
}
.profile-deco-links-link-tag:hover{
    text-decoration: none; 
}
.profile-deco-links{
    display: block;
    padding: 5px 10px;
    border-radius: 5px;
    border-top: 2px solid #fff;
}
.profile-deco-links p{
    text-align: center;
    margin: 0px;
}
.main-username-block-div{
    background-color: #fff;
    /*margin: 10px;*/
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    width: auto;
}
.user-details-labels-p{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    color: #7D7D7D;
}
.user-details-actual-data{
    margin-top: 0px;
    font-size: 16px; 
}
.main-username-block-div button{
    padding: 10px;
    border-radius: 7px;
    background-color: #000099;
    border: 2px solid #ddd;
    color: #fff;
}
.edit-user-info-form-container{
    background-color: #fff;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
}

.edit-user-info-form-container label{
    color: #7D7D7D;
    margin-bottom: 5px;
}
.edit-user-info-form-container input[type="text"],
.edit-user-info-form-container input[type="date"],
.edit-user-info-form-container select {
  border: none;
  border-bottom: 1px solid #000; /* Set the bottom border color */
  outline: none; /* Remove the default focus outline */
  font-size: 16px;
}

/* Style for the bottom border when input field is focused */
.edit-user-info-form-container input[type="text"]:focus,
.edit-user-info-form-container input[type="date"]:focus,
.edit-user-info-form-container select:focus {
  border-bottom: 2px solid #000; /* Increase border thickness or change color */
}

/* Optional: Add margin or padding for spacing */
.edit-user-info-form-container div {
  margin-bottom: 15px;
}
.edit-user-info-form-save-button{
    padding: 10px;
    border-radius: 7px;
    background-color: #000099;
    border: 2px solid #ddd;
    color: #fff;
    margin-right: 10px;
}
.edit-user-info-form-cancel-button{
    padding: 10px;
    border-radius: 7px;
    border: 2px solid #ddd;
    color: #242526;
    margin-right: 10px;
    background-color: #fff;
}
.under-top-profile-but-in-top{
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px; /* Adjust the gap between cards */
    padding: 0px 10px;
}
.under-top-profile-but-in-top-left{
    width: auto;
}
.file-upload-container {
    display: flex;
    align-items: center;
    border-radius: 7px;
    margin: 0px 0px 10px 0px;
}
.file-upload-button {
    background-color: #fff; 
}
  
.file-input {
    /* Custom styles for the file input */
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    width: 75%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
  
.file-input:hover {
    background-color: #e0e0e0;
}
  
.file-input:focus {
    outline: none;
    border-color: #000099; /* Adjust focus styles as needed */
}
  
.upload-button {
    margin-left: 10px;
    padding: 13px;
    background-color: #000099; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
  
.upload-button:disabled {
    opacity: 0.7; /* Reduce opacity when button is disabled */
    cursor: not-allowed;
}
  
.upload-status {
    margin-top: 5px;
    font-size: 14px;
    color: #555; /* Text color for upload status */
}
.tablet-block-two-section{
    margin: 10px;
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px; /* Adjust the gap between cards */
}
.privacy-button-container-box{
    background-color: #fff;
    padding: 10px;
    margin: 0px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.spaceholder-block{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}
.floating-blocks-h2-tags{
    text-align: center;
    font-size: 18px;
    text-decoration: underline;
    text-decoration-color: #000099;
}
.section-explain-privacy-to-user-container{
    display: flex;
    justify-content: center;
}
.section-explain-privacy-to-user{
    color: #7D7D7D;
    max-width: 400px;
    text-align: justify;
    margin-top: 0px;
}
.privacy-toggle-container {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Align items vertically */
}
.privacy-button-div-l div{
    margin-bottom: 10px;
    height: 20px;
}
.privacy-toggle-switch {
    margin-right: 10px; /* Add spacing between the label and the switch */
    padding-top: 50px;
}
.privacy-toggle-switch-flex{
    display: flex;
}
.privacy-toggle-switch-flex p{
    margin: 13px;
    opacity: 0;
}
.bio-container-block{
    background-color: #fff;
    /**/margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.bio-container-block label{
    margin-bottom: 10px;
}
.bio-state-label{
    margin-bottom: 10px;
}
.bio-container-block textarea{
    width: 80%;
    margin-bottom: 7px;
    border: 2px solid #7D7D7D;
    padding: 10px;
    border-radius: 7px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    height: 110px;
}
.edit-bio-button-element{
    padding: 10px;
    margin: 0px;
    border-radius: 7px;
    border: 2px solid #7D7D7D;
}
.submit-bio-button-element{
    background-color: #000099;
    color: #fff;
    border: 2px solid #000099;
    padding: 10px;
    margin-right: 10px;
    border-radius: 7px;
}
.cancel-bio-button-element{
    background-color: #fff;
    color: #242526;
    border: none;
    padding: 10px;
    margin-right: 10px;
    border-radius: 7px;
    border: 2px solid #7D7D7D;
}
.block-to-write-posts{
    background-color: #fff;
    margin: 0px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}
.block-to-write-posts textarea{
    padding: 10px;
    min-width: 250px;
    height: 80px;
    border-radius: 7px;
    border: 2px solid #7D7D7D;
    margin-bottom: 6px;
}
.button-to-submit-post{
    padding: 10px;
    background-color: #000099;
    color: #fff;
    border-radius: 7px;
    border: none;
}
.profile-page-your-posts-div{
    padding: 0px;
}
.your-post-text{
    text-align: center;
    margin: 10px;
    font-size: 18px;
}
.supported-button {
    background-color: #000099; 
}
  
.unsupported-button {
    background-color: #7d7d7d; /* Light gray */
}
.unsupported-button:focus {
    background-color: #7d7d7d;
}



.user-profile-account-aligner-container{
    margin: 0px;
}
.user-profile-account-style-container{
    background-color: #333;
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px; 
}
.profile-short-details-display{
    justify-content: none;
    display: block;
}
.profile-short-details-block{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}
.user-profile-account-img-container{
    display: flex;
    justify-content: center;
}
.user-profile-account-img{
    width: 220px;
    height: 220px;
    border-radius: 50%; 
    border: 5px solid #fff;
}
.user-profile-account-text{
    display: block;
}
.user-profile-account-text-label{
    color: #fff;
    opacity: 40%;
    margin: 0px;
}
.user-profile-account-text-p{
    color: #fff;
    margin-top: 0px;
    word-wrap: break-word
}




.edit-institution-about-page{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    padding: 12px;  
}
.edit-institution-about-page h3{
    margin: 5px 10px 10px 0px;
}
.edit-institution-about-page-textarea-container{
    display: block;
    margin: 0px;
    border: none;
}
.edit-institution-about-page-textarea{
    width: 94%;
    min-height: 200px;
    border-radius: 7px;
    padding: 10px;
}
.edit-institution-about-page-textarea-button{
    padding: 10px;
    border-radius: 7px;
    background-color: #000099;
    color: #fff;
    border: none;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.achievements-textarea{
    padding: 10px;
    border: 2px solid #333;
    border-radius: 7px;
    margin: 0px 10px 10px 0px;
    width: 93%;
}
.achievements-add-button{
    background-color: #fff;
    color: #333;
    font-weight: bold;
    border: 2px solid #000099;
    border-radius: 7px;
    padding: 10px;
    margin-right: 10px;
    margin-top: 15px;
    margin-bottom: 10px;
}
.achievements-submit-button{
    background-color: #000099;
    color: #fff;
    font-weight: bold;
    border: 2px solid #000099;
    border-radius: 7px;
    padding: 10px;
    margin-right: 10px;
    margin-top: 15px;
}
.image-upload-containers{
    background-color: grey;
    padding: 10px;
    border-radius: 7px;
    margin: 10px 0px 0px 0px;
}
.caption-field-textarea{
    width: 94%;
    padding: 10px;
    margin: 10px 0px 15px 0px;
    border-radius: 5px;
    border: none;
}
.submit-image-button{
    background-color: #000099;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    border: none;
}
.file-upload-input[type="file"]::file-selector-button {
    background-color: #fff;
    color: #333;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.current-image-own-show{
    margin-bottom: 5px;
    border-radius: 7px;
}
.image-uploads-main-div{
    display: grid;
    grid-template-columns: auto;
}
.hr-diviver-clear{
    display: block;
}
.institution-about-top-flexbox{
    display: grid;
    grid-template-columns: auto;
    height: auto;
}
.edit-about-achievements{
    margin: 0px;
    padding: 0px;
    border: none;
}



.insitution-about-show-block{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}



@media only screen and (min-width:490px) {
    .user-link-sections-blocks{
        padding: 0% 10%; 
    }
}
@media only screen and (min-width:550px) {
    .open-post-size-controller{
        padding: 0px 85px;
    }
    .mainly-made-for-delete-container{
        right: 140px;
    }
    .mainly-made-for-edit-container{
        right: 90px;
    }
    .user-link-sections-blocks{
        padding: 0% 15%; 
    }
    .comments-f-box{
        border-radius: 7px 7px 0px 0px; 
    }
}
@media only screen and (min-width:620px) {
    .profile-page-your-posts-div{
        padding: 0px 50px;
    }
    .user-profile-account-posts{
        padding: 0px 50px;
    }
    .profile-short-details-block{
        display: flex;
    }
    .profile-short-details-texts{
        margin-left: 20px;
    }
    .profile-short-details-display{
        justify-content: center;
        display: flex;
    }
    .open-post-size-controller{
        padding: 0px 120px;
    }
    .mainly-made-for-delete-container{
        right: 175px;
    }
    .mainly-made-for-edit-container{
        right: 125px;
    }
    
}
@media only screen and (min-width:768px) {
    .under-top-profile-but-in-top{
        /*grid-template-columns: auto auto;*/ 
        grid-template-columns: 50% 48.5%;
        
    }
    .block-to-write-posts textarea{
        width: 92%;
        height: 102px;
    }
    .upload-button {
        margin-left: 10px;
    }
    .file-upload-container {
        margin-left: 0px;
        margin-right: 0px;
    }
    .edit-user-info-form-container{
        background-color: #fff;
        margin-top: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin: 0px;
        padding: 10px;
        border-radius: 10px;
        width: auto;
    }
    .tablet-block-two-section{
        /*grid-template-columns: auto auto;*/
        grid-template-columns: 50% 48.5%;
    }
    .block-to-write-posts{
        margin-top: 10px;
    }
    .open-post-size-controller{
        padding: 0px 190px;
    }
    .mainly-made-for-delete-container{
        right: 245px;
    }
    .mainly-made-for-edit-container{
        right: 195px;
    }
    .profile-page-your-posts-div{
        padding: 0px 100px;
    }
    .user-profile-account-posts{
        padding: 0px 15%;
    }
    .user-link-sections-blocks{
        padding: 0px 0px 10px 10px; 
    }
}

@media only screen and (min-width:850px) {
    .image-uploads-main-div{
        grid-template-columns: auto auto;
        gap: 0px 10px;
    }
    .hr-diviver-clear{
        display: none;
    }
    .institution-about-top-flexbox{
        grid-template-columns: 50% 48.5%;
        max-height: 350px;
        overflow: auto;
    }
    .edit-institution-about-page-textarea-container{
        padding: 0px 10px 0px 0px;
        border-right: 2px solid grey;
    }
    .edit-about-achievements{
        margin: 0px 0px 0px 10px;
    }
    .achievements-textarea{
        padding: 10px;
        margin: 0px 0px 0px 0px;
        width: 97%;
    }
    .edit-institution-about-page-textarea{
        width: 95%;
    }
    
}

@media only screen and (min-width:1024px) {
    .profile-page-main-container{
        width: auto;
        margin-left: 250px;
    }
    .open-post-size-controller{
        margin-left: 250px;
        padding: 0px 130px;
    }
    .comments-f-box{
        margin: 0px;
    }
    .comments-list{
        margin: 0px;
    }
    .mainly-made-for-delete-container{
        right: 185px;
    }
    .mainly-made-for-edit-container{
        right: 135px;
    }
    .profile-page-your-posts-div{
        padding: 0px 200px;
    }
    .user-profile-account-aligner-container{
        margin-left: 250px;
    }
    .user-link-sections-blocks{
        padding: 0px 10px 10px 20px; 
    }
    .image-uploads-main-div{
        grid-template-columns: 33% 33% 33%;
        gap: 0px 10px;
        padding-right:10px ;
    }
}
