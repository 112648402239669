
html {
  /*background-color: #eee;*/
  font-family: Arial, sans-serif;
  background-color: #fff;
}
.app{
  background-color: #eee;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  border-radius: 15px;
}

/* Custom arrow styles */
.slick-prev, .slick-next {
  z-index: 1;
  color: black; /* Change the arrow color to something visible */
  font-size: 24px; /* Increase the arrow size */
  opacity: 0.75; /* Make arrows slightly transparent */
  width: 40px;
  height: 40px;
}

.slick-prev:hover, .slick-next:hover {
  opacity: 1; /* Make arrows fully opaque on hover */
}

.slick-prev::before, .slick-next::before {
  color: white; /* Ensure the arrow icons are visible */
}

.slick-prev {
  left: -45px; /* Adjust the left arrow's position */
}

.slick-next {
  right: -45px; /* Adjust the right arrow's position */
}


.copyright-text-block{
  text-align: center;
  padding: 10px;
  margin: 0px;
  /*background-color: #fff;*/
}
.copyright-text-block p{
  margin: 0px;
  text-align: center; 
}

#root{
  background-color: #fff;
  top: 0px;
  width: auto;   /* 100% of the viewport width */
  /*height: 100vh;  /* 100% of the viewport height */
  box-sizing: border-box; /* Ensures padding and borders are included in the width/height */
}


@media only screen and (min-width:1024px) {
  .copyright-text-block{
    margin-left: 250px;
  }
  .app{ 
    width: auto;
  }
}
@media only screen and (min-width:1200px) {
  .app{
    width: 1280px;
  }
}
