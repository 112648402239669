.multimodal-education-teacher-menu-button.active,
.multimodal-education-teacher-menu-button-subs.active {
  background-color: #000099;
  color: white;
}

.page-title{
    text-align: center;
    color: #333;
    font-size: 25px;
}
.my-container{
    text-align: center;
    margin: 0px;
}
.my-text-container{
    padding: 0px;
}
.my-text-container:hover{
    text-decoration: none;
}
.my-text-container h2{
    margin: 10px 0px;
}
.school-region-name{
    margin: 5px 0px;
}
.my-text{
    color: #222;
    margin: 10px;
    padding: 7px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
} 
.my-multimodal-text{
    color: #222;
    margin: 10px;
    padding: 7px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
} 
.my-text:hover{
    background-color: #000099;
    color: #fff;
}
.search-box-container{
    display: flex;
    justify-content: center;
}
.search-box{
    padding: 10px;
    border: 0px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 10px;
}
.subject-name-in-multimodal {
    font-weight: bold;
    font-size: 20px;
    margin: 10px;
}
  
.topic-text {
    font-weight: bold;
    /* Add any additional styling you want for the topic text here */
}

.multimodal-page-container{
    margin: 10px;
    padding: 0px;
}
.multimodal-blocks-aligner{
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px; 
    width: auto;
    padding: 0px;
}
.multimodal-task-and-objectives{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.task-name-label{
    font-size: 15px;
    margin-bottom: 2px;
    color: #c2c1c1;
}
.task-name-tag{
    font-size: 19px;
    margin-top: 2px;
    margin-bottom: 10px;
    color: #242526;
}
.test-form-main-block{
    display: block;
    margin-top: 10px; 
}
.test-form-main-block-label{
    color: #8d8c8c;
    font-size: 17px;
}
.test-form-main-block-input-field{
    margin-bottom: 15px;
    border: none;
    border-bottom: 2px solid #8d8c8c;
    font-size: 17px;
    margin-top: 5px;
}
.test-form-main-block-input-field:focus{ 
    outline: none;
}
.multimodal-task-and-objectives h3{
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.multimodal-task-and-objectives p{
    margin: 0px 0px 10px 0px;
}
.multimodal-activity-blocks{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    padding: 10px;
}
.multimodal-activity-blocks h2{
    font-size: 18px;
    text-align: center;
    margin: 10px;
}
.submit-multimodal-block h2{
    font-size: 18px;
    text-align: center;
    margin: 10px;
}
.multimodal-activity-material-tag{
    font-weight: bold;
    text-align: center; 
    font-size: large;
}
.multimodal-activity-blocks-ul{
    margin-left: 10px;
    list-style: none;
}
.multimodal-activity-blocks-ul-bullet{
    margin-left: 25px;
}
.multimodal-activity-blocks li{
    margin-bottom: 10px;
}
.ul-list-support-text{
    font-weight: 500;
    margin-bottom: 5px;
}
input[type="checkbox"] {
    margin-right: 10px; /* Space between checkbox and label */
}
.mention-input-container {
    position: relative;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.mention-input-container img{
    width: 100%;
}
.mention-suggestions {
  position: absolute;
  bottom: -4%;
  left: 0;
  z-index: 99;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #333;
}

.mention-suggestions ul {
  list-style-type: none;
  padding: 0;
  margin: 0; 
}

.mention-suggestions li {
  padding: 8px 16px;
  cursor: pointer;
  background-color: #333;
  color: #fff;
}

.mention-suggestions li:hover {
  background-color: #222;
  color: #fff;
}
  
.mention-suggestion {
    padding: 10px;
    cursor: pointer;
}
  
.mention-suggestion:hover {
    background: #f0f0f0;
}
.mention-input-container input{
    padding: 10px;
    border-radius: 7px;
    width: 93%;
    height: auto;
    border: 2px solid #ddd;
}
.multimodal-session-accounts-submit{
    background-color: #fff;
    padding: 10px;
    border: 2px solid #ddd;
    margin: 10px 10px 0px 0px;
    border-radius: 7px;
}
.multimodal-error-message{
    color:red;
    margin: 5px 0px;
}
.submission{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 10px;
}
.submission-h3{
    margin: 10px 0px;
}
.submission-block-ol{
    margin-left: 0px;
    padding-inline-start:20px
}
.submission-block-ol p{
    color: #242526;
    margin-top: 0px;
}
.submission-block-ol input{
    width: 15px;
    height: 15px;
}
.submission-block-flex{
    display: flex;
}
.submission-block-ol img{
    height: auto;
    width: 100%;
}
.instructions-with-label-img{
    display: flex;
}
.submission-block-more-instructions-box{
    padding: 10px;
    color: #fff;
    background-color: #333;
    display: block;
    margin-bottom: 15px;
    border-radius: 10px;
}
.submission-block-more-instructions{
    margin: 10px 0px !important;
    color: #fff !important;
}
.link-input-container{
    margin-bottom: 10px;
    margin-top: 10px;
}
.school-mention-list-ul{
    list-style: none;
}
.school-mention-list-ul li{
    background-color: #242526;
    padding: 10px;
    color: #fff;
    

}
.multimodal-education-teacher-menu{
    display: flex;
    justify-content: space-evenly;
}
.multimodal-education-teacher-menu-button{
    width: 28%;
    text-align: center;
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 7px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.rubric-page-container{
    border-radius: 10px;
}

.multimodal-submissions-section{
    display: block;
}
.multimodal-submissions-section-h2{
    font-size: 18px;
}
.multimodal-submissions-section-li{
    color: #242526;
    padding: 10px;
    margin-bottom: 10px;
}
.assignment-link-block{
    color: #242526;
    padding: 10px;
    background-color: #fff;
    border-radius: 7px;
    border: 2px solid #ddd;
    width: auto;
    margin-top: 5px;
    margin-bottom: 10px;
}
.submissions-list-block{
    display: block;
}
.submission-item{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.submission-item-group-name{
    font-size: 15px;
    margin: 10px 0px;
}
.submission-item-group-flex-block{
    display: block;
}
.submission-item-group-flex-block label{
    color: #8d8c8c;
}
.submission-item-group-flex-block p{
    margin: 0px 0px 15px 0px ;
}
.submission-mentions-list{
    margin-left: 10px !important;
}
.rubric-class-container h4{
    margin: 10px 0px;
}
.button-scoring-system-container{
    padding: 10px;
    border-radius: 7px;
    border: 2px solid #ddd;
}
.button-scoring-system-h5{
    margin: 7px;
    text-align: center;
}
.button-scoring-system{
    background-color: red;
    display: flex;
    justify-content: space-evenly;
    margin: 10px 0px 15px 0px;
}
.button-scoring-system button{
    width: 20%;
}
.button-scoring-system-label{
    text-align: justify;
    text-justify: inter-word;
}
.soft-copy-link-tag{
    color: #242526;
    text-decoration: none;
    font-weight: bold;
}
.submission-mentions-tag{
    font-weight: bold;
    margin: 0px 0px 5px 0px;
}
.submission-mentions-list{
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 7px;
}
.range-inputs-container{
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px; 
}
.category{
    border: 2px solid #ccc;
    padding: 10px;
    border-radius: 10px;
}
.range-input input{
    padding: 0px;
    width: 95%;
    color: #000099;
}
.range-input span{
    color: #000099;
    font-weight: bold;
}
.input-spacing{
    background-color: red;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 5px;
}
.input-spacing p{
    margin: 0px;
}
.search-bar-container{
    display: flex;
    justify-content: flex-start;
}
.submissions-searchbox{
    padding: 10px;
    border: none;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;    
    margin: 5px 0px 15px 0px;
    width: 100%;
}
.score-update-submit{
    padding: 10px;
    background-color: #000099;
    color: #fff;
    border: none;
    border-radius: 7px;
}

.marked-list-container-ul-container{
    margin: 0px;
    padding: 0px;
}
.marked-list-container-ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px;
}
.marked-list-blocks{
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.marked-list-blocks p{
    padding: 0px;
    margin: 0px;
}

.marked-list-div-aligner{
    display: block;
}
.marked-list-div-aligner label{
    color: #8d8c8c;
}
.marked-list-div-aligner p{
    margin-left: 10px;
    margin-bottom: 10px;
}
.marked-list-div-aligner a{
    color: #242526;
    margin-left: 10px;
}
.marked-list-div-aligner a:hover{
    text-decoration: none;
}
.marked-list-group-name{
    font-size: 20px;
}
.marked-list-div-aligner-score{
    margin-bottom: 10px;
}
.marked-list-div-aligner-score label{
    border: 2px solid #fff;
}
.marked-list-div-aligner-score p{
    font-weight: bold;
    margin-left: 10px;
}
.page-detailed-setup{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.marked-moda-left-div{
    /**/ margin-right: 10px; 
    min-width: 45%;
}
.marked-moda-left-div div{
    border-bottom: 2px solid #ddd;
    margin-bottom: 10px;
}
.marked-moda-right-div p{
    border-bottom: 2px solid #ddd;
    font-weight: bold;
    margin-bottom: 10px;
}
.marked-list-div-aligner-score-total{
    display: flex;
    justify-content: center;
}
.marked-list-div-aligner-score-total p{
    font-weight: bold;
    margin-left: 5px;
}



.topic-notes-block{
    background-color: #fff;
    margin: 10px;
    border-radius: 10px;
    padding: 20px; 
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-left: auto;
    margin-right: auto;
    width:auto;
}
.topic-notes-block h2{
    margin: 10px 0px 5px 0px;
    text-decoration: underline;
}
.topic-notes-block h3{
    margin: 10px 0px 0px 0px;
    text-decoration: underline;
}
.topic-notes-block ul{
    margin-left: 25px;
}
.general-delaration{
    color: #000099;
}
.general-delaration-link{
    color: #000099;
    text-decoration: underline; 
}
  



@media only screen and (min-width:620px) {
    .multimodal-page-container{
        padding: 0px 10%;
    }
    .mention-input-container img{
        width: 80%;
    }
}
@media only screen and (min-width:768px) {
    .mention-input-container img{
        width: 60%;
    }
}
@media only screen and (min-width:900px) {
    .mention-input-container img{
        width: 45%;
    }
    .topic-notes-block{
        width: 75%;
    }
}
@media only screen and (min-width:1024px) {
    .multimodal-page-container{
        margin-left: 250px;
    }
}