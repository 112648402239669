/* LoadingSpinner.css */

.loading-spinner {
    /* Make the container cover the entire viewport */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    /* Center the content horizontally and vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000; 
  }
.loading-spinner-gif {
    /* Optionally, you can add styles for the spinner image */
    width: 80px;
    height: 80px;
    z-index: 1000000;
}