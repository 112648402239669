.stats-record-page-main-container-for-two{
    display: block;
    margin: 0px;
    width: auto;
    padding: 0px;
    /*height: 100vh;*/
}
ul{
    margin: 0px;
    padding: 0px;
}
li{
    margin: 0px;
    background-color: #fff;
}
.current-user-scores{
    border: 2px solid #d3d3d3;
    margin: 0px 0px 10px 0px;
    list-style: none;
    border-radius: 10px;
    padding: 0px 10px 10px 10px;
    background-color: #fff;
}
.record-not-available-list{
    border: 2px solid #d3d3d3;
    padding: 10px;
    border-radius: 10px;
    list-style: none;
    margin: 0px;

}
.tries-record{
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min-content, 1fr));
    gap: 10px 10px; 
}
.tries-record-list{
    margin-bottom: 0px;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #d3d3d3;
    list-style: none;
    width: auto;
}
.tries-record-list p{
    margin: 0px;
}


.tracked-user-scores{
    border: none;
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.tracked-user-score-list{
    list-style: none;
}
.link-to-tracked-users-account{
    color: #242526;
    display: flex;
    
}
.link-to-tracked-users-account p{
    margin: 0px;
}
.link-to-tracked-users-account:hover{
    text-decoration: none;
}
.tracked-user-name{
    padding: 0px;
    margin: 0px;
}
.tracked-user-account{
    padding-left: 5px;
}
.search-box-field{
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 7px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.current-user-marks-record-sheet{
    padding: 0px 5px 0px 5px;
}
.tracking-user-marks-record-sheet{
    padding: 0px 5px 0px 5px;
}
.multimodal-stats-for-user-container{
    margin: 0px 5px;
    padding-bottom: 10px;
}
.multimodal-stats-for-user-container-h2{
    font-size: 18px;
    text-align: center;
}
.multimodal-stats-for-user{
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px;
    list-style: none;
}
.multimodal-stats-for-user-li{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    border-radius: 10px;
}

.calender-content-page{
    padding: 0px 10px 10px 10px;
}
/*
.calendar-container {
    display: flex;
    flex-direction: column;
    padding: 0px;
}
*/
.calendar-content-page-h2{
    font-size: 23px;
    text-align: center;
    text-decoration: underline;
    margin-top: 0px;
}
.calendar-container {
    display: grid;
    grid-template-columns: auto; 
    gap: 15px 15px;
    padding: 0px;
}
  
.month {    
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.month h2{
    font-size: 18px;
}   
  
.calendar-grid {
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
  
.calendar-weekdays, .calendar-week {
    display: flex;
}
  
.calendar-day {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    text-align: center;
}
  
.calendar-day:not(:empty) {
    background-color: #f9f9f9;
}
.today-date{
    padding-bottom: 10px;
}
.color-label-input{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    color: #333;
    padding: 10px;
    border-radius: 10px;
}
.color-label-input h2{
    text-decoration: underline;
    text-align: center;
    margin: 5px 0px 15px 0px;
}
.color-label-input-select {
    /* styles for the select element */
    padding: 8px;
    border: 2px solid #ddd;
    border-radius: 4px;
    width: 150px; /* adjust width as needed */
    background-color: #fff;
    color: #333;
    outline: none; /* remove outline on focus */
    margin-bottom: 10px;
}
.color-label-input-select option {
    /* styles for the options within the select */
    background-color: #fff;
    color: #333;
    margin: 10px;
}
.select-color-label-input{
    padding: 10px;
    border-radius: 7px;
    border: 2px solid #ddd;
    background-color: #fff;
    color: #333;
    margin-bottom: 10px;
}
.submit-button{
    padding: 10px;
    border-radius: 7px;
    background-color: #000099;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    margin-bottom: 10px;
}
.day-label{
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 110%;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 5; 
}

@media only screen and (min-width: 500px) {
    .tries-record{
        grid-template-columns: repeat(auto-fill, minmax(min-content, calc(50% - 10px)));
    }
    .tries-record-list{
        max-width: 100%;
    }
    .current-user-scores{
        padding: 0px 0px 10px 10px;
    }
    .tracked-user-scores{
        padding: 5px 0px 10px 10px;
    }
}
@media only screen and (min-width: 768px) {
    .stats-record-page-main-container-for-two{
        padding: 0px 5%;
    }
    /*
    .calendar-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0px 20px;
    }
    */
    .calendar-container {
        grid-template-columns: auto auto; 
        gap: 20px 20px;
        padding: 0px 20px;
    }
    .calender-content-page{
        padding: 0px;
    }
    


}
@media only screen and (min-width: 900px) {
    .stats-record-page-main-container-for-two{
        padding: 0px 7%;
    }
    .calendar-container {
        padding: 10px 20px;
    }
    
}
@media only screen and (min-width: 1024px) {
    .stats-record-page-main-container-for-two{
        margin-left: 250px;
        padding: 0px 10%;
    }
}