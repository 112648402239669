.auth-return-div{
    background-color: #000099; 
    height: 1400px
}
.auth-screen-main-div{
    width: 100%;
    overflow-y: auto;
    background-color: #000099;
}
.login-div-one{
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.login-form {
    opacity: 0;
    transition: opacity 0.3s ease-in-out; 
}
.login-form.visible {
    opacity: 1;
}
.image-test-block{
    width: 100%;
}
.image-test-block-large{
    width: 100%;
    display: none;
}
.color-transition-block {
    text-align: center;  
    color: #242526;
    margin: 0px;
    padding: 0px;
}
.logo-img{
    margin: 10px 0px;
    width: 160px;
    height: auto;
}
.color-transition-block h1{
    margin-top: 0px;
    font-size: 26px;
}
.color-transition-block p{
    margin-bottom: 0px;
}
.color-transition{
    background-color: #fff;
}
  
.hero-image {
    background-image: url("../componentsCSS/styling/wave.png");
    background-color: #cccccc;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.form-div-box{
    /*position: absolute;
    /*width: 100%;*/
    width: 80%;
    overflow: hidden;
}
.form-div-box h3{
    color: #fff;
}
.form-div-box p{
    color: #fff;
}
.form-div-box .links{
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
}
.form-container {
    background-color: #000099;
    display: flex; 
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
}

.login-form-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 15px;
    border-radius: 10px;
}
.login-form-block label {
    margin-bottom: 0px;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #242526;
}
.second-stage-select-field {
    padding: 10px;
    min-width: 180px;
    border-radius: 7px;
    border: 2px solid #ddd;
}
.second-stage-select-field option{
    background-color: #fff;
    padding: 10px;
}
.second-stage-select-field option:hover{
    background-color: #000099;
}
.login-form-block input[type="date"]{
    padding: 10px;
    min-width: 180px;
    border-radius: 7px;
    border: 2px solid #ddd;
}
.login-form-block input[type="text"],
.login-form-block input[type="password"] {
    border: none;
    border-bottom: 1px solid #ccc; /* Default border */
    padding: 10px 0px 0px 0px;
    margin-bottom: 20px;
    width: 100%;
    font-size: 16px;
}
.login-form-block input[type="text"]:focus,
.login-form-block input[type="password"]:focus {
  outline: none; /* Remove default focus outline */
  border-bottom: 1px solid #000099; /* Adjust thickness and color */
  font-size: 16px;
}
  
  .login-form-block button.show-password {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-top: 0px;
  }
  .login-form-block .error-response {
    color: red;
  }
  
  .login-form-block button[type="submit"] {
    background-color: #000099;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-form-block button[type="submit"]:hover {
    background-color: #0056b3;
  }
.show-password{
    padding: 10px;
    margin: 10px;
}
.div-flex-box{
    display: flex;
    width: 100%;
}
.show-password-flex{
    display: flex;
    width: 100%;
}
.situation-controller-container{
    display: flex;
    justify-content: space-evenly;
    padding: 0px 10px 10px 10px;
}
.situation-controller{
    color: #fff;
    margin-bottom: 10px;
}
.situation-controller-active{
    color: #fff;
    border-bottom: 2px solid #fff;
    margin-bottom: 10px;
}
.aligner-div-block{
    width: 100px;
}
.auth-icons{
    color: #242526;
    padding-top: 10px;
}
.password-requirements{
    margin-left: 20px;
    margin-bottom: 10px;
}

.unauthenticated-page-block-container{
    padding: 10px;
}
.unauthenticated-page-aligner-block{
    display: flex;
    justify-content: center;
}



.color-transition-block h1{
    margin-bottom: 0px;
}
.color-transition-block p{
    margin-top: 0px;
}
.page-title-name-auth{
    text-align: left;
    color: #242526;

}
.links{
    color: #000099; 
    margin-bottom: 5px;
}
.error-response{
    color: red;
    margin-bottom: 5px;
}
.success-response{
    color: green;
    margin-bottom: 5px;
}
.contributors-container {
    text-align: center;
    padding: 10px;
}
  
.contributors-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Enable flex-wrap */
    gap: 10px; /* Add some space between the cards */
}
  
.contributor-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 200px; /* Fixed width */
    padding: 10px;
    text-align: center;
    margin: 10px;
}
  
.contributor-image {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
}
  
.contributor-info h3 {
    margin: 10px 0 5px;
    font-size: 1.2em;
}
  
.contributor-info p {
    margin: 0;
    color: #777;
}

@media only screen and (min-width:412px) {
    .form-div-box{
        max-width: 400px;
    }
}
@media only screen and (min-width:520px) {
    .contributors-list{
        grid-template-columns: auto auto; 
    }
}
@media only screen and (min-width:700px) {
    .contributors-list{
        grid-template-columns: auto auto auto; 
    }
    .contributors-container{
        padding: 0px 30px;
    }
    .image-test-block{
        display: none;
    }
    .image-test-block-large{
        display: block;
    }
}
@media only screen and (min-width:768px) {
    .contributors-list{
        grid-template-columns: auto auto auto auto;  
    }
}
@media only screen and (min-width:768px) {
    .logo-img{
        width: 180px;
    }
}