.oer-main-material-page{
    display: block;
}
.oer-subject-name{
    font-size: 22px;
    text-align: center;
    text-decoration: underline;
    margin: 0px;
    padding: 0px 15px;
}
.oer-resource-type-name{
    padding: 10px 0px 0px 0px;
    margin: 0px;
}
.oer-resource-type-links{
    color: #242526;
}
.oer-resource-type-link{
    display: block;
    text-decoration: none;
}
.oer-resource-type-link-anchor{
    color: #242526;
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;
    border-radius: 7px;
    /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
    border: solid 2px #ddd; 
    text-decoration: none;
}
.oer-resource-type-link-anchor:hover{
    color: #fff;
    background-color: #000099;
    text-decoration: none; 
}
.subjects-and-resources-links-block{
    margin: 0px 10px 10px 10px;
    padding: 10px 10px 0px 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.subjects-and-resources-links-block-log{
    margin: 0px 0px 10px 0px;
    padding: 10px;
    border-radius: 10px;
    background-color: red;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.oer-resource-type-block{
    margin: 10px 0px;
    display: grid;
    grid-template-columns: auto; 
}
.reach-out-email-tab{
    color: blue;
    text-decoration: underline;
}



.modal-overlay-section{
    background-color: #fff;
}
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Darker overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000; /* Ensure this modal is above other content */
}
  
.custom-modal-content {
    background: #ffffff; /* White background for the modal */
    padding: 30px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Soft shadow */
    width: 400px; /* Fixed width */
    max-width: 90%; /* Responsive for smaller screens */
    position: relative; /* For positioning close button */
}
  
.custom-modal-title {
    margin: 0 0 15px; /* Margin for title */
    font-size: 1.5em; /* Title size */
}
  
.custom-modal-text {
    margin: 0 0 20px; /* Margin for paragraph */
    font-size: 1em; /* Text size */
}
  
.custom-modal-close-button {
    padding: 10px 20px; /* Button padding */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    background-color: #007bff; /* Button color */
    color: white; /* Button text color */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.3s; /* Smooth transition */
}
  
.custom-modal-close-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

  

@media only screen and (min-width: 768px) {
    .subjects-and-resources-links-block{
        margin: 0px 50px 10px 50px;
    }
    .oer-resource-type-block{
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
        gap: 10px 10px;
    }
} 