.page-title{
    text-align: center;
    color: #242526;
    text-decoration: underline;
    font-size: 25px;
}
.page-space-manager{
    background-color: #eee;
    margin: 0px;
    width: auto;
    border-radius: 15px;
}
.account-main-container-box{
    border-radius: 10px;
    margin: 0px;
}
.account-main-container-box-aligned{
    /*
    display: flex;
    justify-content: center;
    */
    padding: 0px;
    margin: 0px;
}
.accounts-ol-block-list{
    padding: 0px 0px 0px 0px; 
    display: grid;
    /*max-width: 430px;*/
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));     
    gap: 10px 10px; /* Adjust the gap between cards */
    padding: 0px 10px;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}
.accounts-ol-block-list .user-container-main-box{
    list-style: none;
}
.user-container-main-box{
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    max-width:100%;
    background-color: #fff;
    word-wrap: break-word;
}
.accounts-user-details{
    width: 100%;
}
.user-details-block{
    color: #242526;
    display: flex;
}
.user-details-block:hover{
    text-decoration: none;
}
.account-name-tag{
    opacity: 75%;
}
.user-details-profile-image{
    width: 130px;
    height: 130px;
    border-radius: 5px;
    margin-right: 10px;
    border: 2px solid #000099;
}


.search-container{
    display: flex;
    justify-content: center;
}
.search-input{
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
}
.buttons-position-control{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}
.add-connection-button{
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin: 10px 0px 0px 0px;
}
.track-activities-button{
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin: 10px 0px 0px 10px;
    padding: 10px;
}
.active-activities-button{
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin: 10px 10px 0px 0px;
    padding: 10px;
}
.strike-button{
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin: 10px 0px 0px 10px;
    padding: 10px;
}
.accounts-ad-container{ 
    padding: 0px;
    list-style: none;
    border-radius: 10px;
    justify-content: center;
    display: flex;
}
.accounts-ad-container div{
    width: 100%; 
    justify-content: center;
    display: flex;
}


.admin-stats{
    background-color: #fff;
    border-radius: 10px;
    margin: 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
}
.admin-stats h2{
    margin: 0px;
    padding: 10px 0px;
}
.admin-stats p{
    margin: 0px;
    padding: 5px 0px;
}


.filing-assessment-block{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.filing-assessment-block h3{
    margin: 0px;
    padding: 10px 0px; 
}
.filing-assessment-link-container{
    background-color: #fff;
    border: 2px solid #ddd;
    padding: 10px;
    border-radius: 7px;
    margin-top: 10px;
}
.filing-assessment-link{
    color: #242526;
}
.filing-assessment-link-container:hover{
    color: #fff;
    text-decoration: none;
    background-color: #000099;
}
.one-question-block{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #ddd;
}
/* Overlay for the modal */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dims the background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures it’s on top of everything */
}

/* Modal Container */
.custom-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 400px; /* You can adjust the width if needed */
    text-align: center;
}

/* Modal Title */
.custom-modal-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
}

/* Modal Score Paragraph */
.custom-modal-score {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #555;
}

/* Button Container */
.custom-modal-buttons {
    display: flex;
    justify-content: space-between;
}

/* Proceed Button */
.custom-modal-proceed {
    background-color: #000099; /* Dark blue color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem; 
}

.custom-modal-proceed:hover {
    background-color: #0000cc; /* Slightly lighter blue on hover */
}

/* Cancel Button */
.custom-modal-cancel {
    background-color: #f44336; /* Red cancel button */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem; 
}

.custom-modal-cancel:hover {
    background-color: #e57373; /* Lighter red on hover */
}


/* Styles for the ActivitiesPage */
.activities-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px; /* Space between cards */
    padding: 10px; /* Padding around the grid */
    justify-items: center; /* Center items in each grid cell */
    margin: auto;
}

.activity-card {
    background-color: #ffffff; /* White background for cards */
    border: 1px solid #e0e0e0; /* Light gray border */
    border-radius: 8px; /* Rounded corners */
    padding: 16px; /* Inner spacing for content */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; /* Subtle shadow */
    text-align: center; /* Center-align text */
    transition: transform 0.2s; /* Smooth scaling on hover */
    text-decoration: none; /* Remove underline from links */
    color: #333; /* Dark gray text color */
}

.activity-card:hover {
    transform: scale(1.05); /* Slightly enlarge the card on hover */
}
.expired-card {
    background-color: #f8d7da; /* Light red background */
    border: 1px solid #f5c6cb; /* Red border */
    color: #721c24; /* Dark red text */
}
.activity-availabilty-check-container{
    text-align: center;
}
.activity-availabilty-check{
    padding: 10px;
    background-color: #fff;
    color: #242526;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
}
.activity-page {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    max-width: 800px;
    margin: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.activity-page h1 {
    font-size: 28px;
    margin-bottom: 10px;
}

.activity-page p {
    font-size: 18px;
    margin-bottom: 8px;
}

.activity-page .error {
    color: red;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.activity-card-link-div{
    padding: 10px 0px;
}
.activity-link{
    padding: 10px;
    border-radius: 7px;
    background-color: #000099;
    color: #eee;
    margin-bottom: 15px;
    margin-top: 10px; 
}
.activity-link:hover{
    text-decoration: none;
}
.activity-page-main-border{
    margin: auto; 
}
.all-activity-block{
    margin: 0px 10px 10px 10px;
    background-color: #fff;
    padding: 10px 35px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: auto;
}
.activity-question-select{
    border-top: 2px #ddd;
    border-left: 0px;
    border-bottom: 0px;
    border-right: 0px;
    border-style: dashed;
    padding-bottom: 10px;
}
.activity-question-select h2{
    margin: 0px;
    padding: 10px 0px;
}
.activity-submit-button{
    background-color: #000099;
    color: #fff;
    border: none;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
}
.filing-system-page-container{
    margin: 10px;
    width: auto;
}
.filing-system-page{
    /* background-color: #fff; */
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));     
    gap: 10px 10px; 
}
.filing-system-p-tag{
    margin: 0px;
    padding-bottom: 10px;
    text-align: center;
}
.search-filing-names-container{
    display: block;
    background-color: #fff;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}
.search-filing-names{
    display: block;
}
.search-filing-names h3{
    margin: 0px;
    padding-bottom: 10px;
}
.search-filing-names input{
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 7px;
}
.filtered-accounts{
    background-color: #ddd; 
    border-radius: 10px;
    padding: 10px 10px 5px 10px;
}
.filtered-accounts h3{
    margin: 0;
    padding-bottom: 10px;
}
.filtered-accounts ul{
    border-radius: 7px;
}
.filtered-accounts li{
    list-style: none;
    padding: 10px;
    margin: 10px 0px; 
    border-radius: 5px;
}
.selected-accounts{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.selected-accounts h3{
    margin: 0px;
    padding: 10px 0px;
}
.selected-accounts ul{
    display: block;
    padding: 0px;
    overflow: auto;
    max-height: 250px;
}
.selected-accounts li{
    list-style: none;
    border: 2px solid grey;
    padding: 10px; 
    border-radius: 7px;
    margin: 10px 0px;
}
.selected-accounts-remove-button{
    background-color: #a6a6a6;
    color: #242526;
    padding: 4px;
    border-radius: 5px;
    margin-left: 5px;
    border: none;
}
.selected-accounts-submit-button{
    padding: 10px;
    border: none;
    border-radius: 7px;
    background-color: #000099;
    color: #fff;
    margin-top: 5px;
}
.activity-dev-system-page{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    padding: 10px;
}
.activity-dev-system-page h2{ 
    margin: 0px;
    padding: 10px 0px;
}
.main-activity-info-label{
    margin-bottom: 10px; 
}
.main-activity-info-label input{
    padding: 10px;
    border-radius: 7px;
    border: 2px solid #ddd;
    margin-bottom: 10px;
}
.main-activity-info-label select{
    padding: 10px;
    border-radius: 7px;
    border: 2px solid #ddd;
    margin-bottom: 10px;
}
.pdf-upload-label input{
    margin-bottom: 10px;
    padding: 10px;
    border: solid 2px #000099;
    border-radius: 7px;
}
.activity-question-label-block{
    display: block;
}
.activity-question-label-block input{
    border: 2px solid #ddd;
    padding: 10px;
    border-radius: 7px;
    margin-bottom: 12px;
    width: 92%; 
    margin-top: 10px;
}
.activity-option-map{
    padding-bottom: 10px;
    border-top: 2px dotted #3d3d3d;
    padding-top: 10px;
}
.activity-option-map input{
    border: 2px solid #ddd;
    padding: 10px;
    border-radius: 7px; 
    width: 92%;  
    height: 60px;
}
.activity-option-map-label{ 
    margin-bottom: 10px;   
}
.correct-answer-div input{
    border: 2px solid #ddd;
    padding: 10px;
    border-radius: 7px;  
}
.add-more-question-button{
    background-color: #ddd;
    padding: 10px;
    border-radius: 7px;
    border: none;
    margin-right: 10px;
    margin-top: 10px;
}
.submit-activity-button{
    background-color: #000099;
    color: #fff;
    padding: 10px;
    border-radius: 7px;
    border: none;
    margin-top: 10px;
}
.add-more-questions-aligner{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));     
    gap: 10px 10px; /* Adjust the gap between cards */
}
.LAQ-activity-input-field{
    border-radius: 7px;
    border: 2px solid #ddd;
    padding: 10px;
}
.LAQ-activity-mark-value{
    margin: 0px;
    padding: 0px 0px 10px 0px;
}
.LAQ-activity-submit{
    padding: 10px;
    border-radius: 7px;
    border: none;
    background-color: #000099;
    color: #fff;
    margin: 10px 0px;
}

.LAQ-submissions-list{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));     
    gap: 10px 10px;
}
.LAQ-submissions-list li{
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    list-style: none;
}
.submit-assessed-LAQ-activity{
    padding: 10px;
    border-radius: 7px;
    border: none;
    background-color: #000099;
    color: #fff;
    margin-top: 15px;
}
.LAQ-assessment-main{
    margin: 0px 10px;
}
.comments-laq-activity-assess-label{
    margin-top: 15px;
}
.comments-laq-activity-assess{
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 7px; 
}
   
/* Container for activity scores */
.activity-scores-container {
    padding: 0px;
    background-color: #eee;
}

/* Header styling */
.activity-scores-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

/* Each activity group */
.activity-group {
    margin-bottom: 30px;
}

/* Activity name header for each group */
.activity-name {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
}

/* List container that becomes a grid */
.activity-scores-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
    grid-gap: 10px; /* Gap between grid items */
    list-style-type: none;
    padding: 0;
}

/* Each list item (score block) styling */
.activity-score-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

/* Add a hover effect for better interactivity */
.activity-score-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Details of each activity score */
.activity-score-details {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Space between lines */
}

/* Styling for each element inside a score block */
.activity-score-name {
    font-size: 18px;
    font-weight: bold;
}

.activity-score-activity {
    font-size: 16px;
    color: #555;
}

.activity-score-score {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

.activity-score-comments {
    white-space: nowrap;        /* Prevent wrapping */
    overflow: hidden;           /* Hide overflow */
    text-overflow: ellipsis;    /* Show ellipsis if truncated */
    cursor: pointer;            /* Indicate clickable */
    transition: color 0.3s ease;
}

.activity-score-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: box-shadow 0.2s ease, background-color 0.2s ease;
}

.activity-score-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
 

.portal-activity-scores-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));     
    gap: 10px 10px; /* Adjust the gap between cards */
}
.activity-score-card {
    padding: 15px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.activity-score-card h3 {
    margin: 0 0 0px 0px; /* Margin for card title */
}
.activity-score-card label {
    color: grey; 
    margin-bottom: 5px; 
    font-size: small;
}
.activity-score-card p {
    margin: 5px 0; /* Margin for paragraphs */
}
.section-buttons-container {
    margin-bottom: 20px;
}
  
.section-buttons button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.active-button {
    background-color: #000099; /* Green when active */
    color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
  
.inactive-button {
    background-color: #fff; /* Light grey when inactive */
    color: black;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
  
.active-button:hover, .inactive-button:hover {
    background-color: #ddd; /* Hover effect */
}
  

.enrollment-record-to-be-processed{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));     
    gap: 10px 10px;
}
.enrollment-record-to-be-processed li{
    margin: 0px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    list-style: none;

}
.enrollment-record-to-be-processed button{
    padding: 10px;
    border-radius: 7px;
    border: none;
    background-color: #000099;
    color: #fff;
    
}


/* Media Queries for Responsiveness */
@media (max-width: 600px) {
    .activities-container { 
        grid-template-columns: 1fr; /* Stack cards on smaller screens */
    }  
}
@media only screen and (min-width:550px) {
    .account-main-container-box-aligned{
        padding: 0px 15%;
    }
}
@media only screen and (min-width:767px) {
    .account-main-container-box-aligned{
        padding: 0px 20px;
    }
    
}
@media only screen and (min-width:768px) {
    .accounts-ol-block-list{
        padding: 0px 25px;
    }
    .user-container-main-box{
        max-width:460px; 
    }
    .all-activity-block{
        margin: 0px 30px 10px 30px; 
    }
}
@media only screen and (min-width:900px) {
    .filing-system-page-container{
        margin: 0px 50px;
    }
    .activities-container {
        margin: 0px 50px;
    }
    .LAQ-assessment-main{
        margin: 0px 50px;
    }
    .all-activity-block{
        margin: 0px 45px 10px 45px; 
    }
}
@media only screen and (min-width: 1024px) {
    /*
    .account-main-container-box-aligned{
        margin-left: 250px;
    }
    */
    .page-space-manager{
        margin-left: 250px;
    }
    .accounts-ol-block-list{
        padding: 0px 30px;
    }
    .user-container-main-box{
        max-width: 100%; 
    }
    .accounts-ad-container div{
        width: 90%;  
    }
    .activity-page-main-border{
        margin-left: 250px;
    }
}