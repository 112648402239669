.skill-studio-admin-section{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    justify-content: center;
    margin: auto;
}
.skill-studio-admin-section input{
    margin-bottom: 15px;
    padding: 5px;
    border-radius: 7px;
    border: 2px solid #eee;
}
.skill-studio-admin-section textarea{
    margin-bottom: 15px;
    padding: 5px;
    border-radius: 7px;
    border: 2px solid #eee;
}
.bullet-point-button{
    background-color: gold;
    color: #242526;
    padding: 10px;
    border-radius: 7px ;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
}
.admin-submit-skill{
    background-color: #000099;
    color: #fff;
    padding: 10px;
    border-radius: 7px ;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    margin-bottom: 10px;
}
.general-section-div-box{
    margin: 15px;
    width: auto;
}
.skill-studio-search-box{
    padding: 10px;
    border-radius: 7px;
    border: 2px solid #eee;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 10px;
}
.skill-studio-ul{
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 10px;
    width: auto;
}
.skill-studio-ul li{
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    border-radius: 10px;
}
.skill-studio-div{
    text-align: center;
}
.skill-studio-div p{
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 10px;
}
.skill-studio-div strong{
    font-size: 14px;
}
.skill-studio-link {
    color: #242526;
    background-color: #fff;
}
.skill-studio-link:hover {
    display: block;
}
.skill-studio-ul-support-div{
   display: flex;
   justify-content: center;
   margin-top: 10px;
}
.opened-studio-task{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: auto;
}
.pdf-open-trigger{
    background-color: #000099;
    color: #fff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
}

@media only screen and (min-width: 670px) {
    .opened-studio-task{
        margin: 0px 10% 10px 10%;
    }
    .skill-studio-ul{
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    }
}
@media only screen and (min-width: 768px) {
    .skill-studio-admin-section{
        margin: 0px 15%;
    }
    .opened-studio-task{
        margin: 0px 15% 10px 15%;
    }
}
@media only screen and (min-width: 900px) {
    .skill-studio-ul{
        width: 90%; 
    }
    .skill-studio-ul{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}