.main-card-block{
    background-image: url('../images/animals/v2-4n40b-wip1b.jpg');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
    width: auto;
    margin: 10px;
}
.cover-link{
    color: #212121;
    text-decoration: none;
    padding: 5px;
}
.cover-link:hover{
    text-decoration: none;
}
.title-cover{
    width: auto;
    background-color: #fff;
    width: 400px;
    border-radius: 10px;
    text-align: center;
}
.card-title{
    width: auto;

}
.record-bar{
    display: flex;
    justify-content: space-evenly;
    border-radius: 10px;
    margin: 0px 0px 10px 0px;
}
.record-div{
    background-color: #fff;
    border-radius: 10px;
    width: 15%;
    text-align: center;
}