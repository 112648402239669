.main-wall-container {
    padding: 10px;
    background-color: red;
} 
.activity-page-side-aligner{
    margin: auto;
    padding: 0px;
}
.activity-wall-container{
    display: block;
    /*height: 100vh;*/
}
.multiple-q-tested-wall-container{
    display: block;
    margin: auto;
    padding: 0px 10px;
}
.tested-activity-wall-container{
    display: block;
    margin: auto;
    padding: 0px 10px;
    
}
.activity-block-height-set{
    margin-bottom: 10px;
    height: 100vh; 
}
.running-activity-section{
    padding: 0px 10px 10px 10px;
}
.running-game-over-section{
    padding: 0px 10px 10px 10px;
}
.activity-name-tag-h1{
    font-size: 25px;
    text-decoration: underline;
    text-align: center;
    margin: 0px;
    padding: 15px;
}
.activity-rounds-tag-h2{
    font-size: 20px;
    text-align: center;
}
.activity-rounds-tag-h4{
    text-align: center;
    margin: 10px 0px;
}
.activity-rounds-tag-h2-left{
    font-size: 20px;
}
.activity-instructions-tag-h3{
    font-size: 17px;
    margin: 10px;
}
.rounds-count{
    font-size: 17px;
    margin: 10px;
    text-align: center;
}
.activity-instructions-tag-h4{
    font-size: 14px;
    margin: 10px;
    text-align: center;
}
.counting-activity-input-field{
    padding: 10px;
    border-radius: 7px;
    border: 2px solid #ddd;
}
.activity-submit-button{
    padding: 10px;
    border-radius: 7px;
    border: none;
    background-color: #000099;
    color: #fff;
}
.multiple-choice-ul{
    display: flex;
    flex-direction: column;
}
.multiple-choice-ul-block{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.multiple-questions-game-over-ul li{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.multiple-choice-ul-block img{
    max-width: 100%;
}
.round-high-score{
    margin: 0px 0px;
}
.round-high-score h2{
    margin-top: 0px;
    font-size: 19px;
    text-align: center;
}
.round-high-score p{
    margin-bottom: 0px;
    margin: 10px 10px 10px 0px;
    display: flex;
    justify-content: flex-end;
}
.counting-activity-rounds-block{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min-content, 1fr));
    gap: 10px 10px; 
}
.counting-activity-rounds-block-divs{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.counting-activity-rounds-block-divs h3{
    margin: 0px;
    padding: 10px 0px;
}
.symbol-container-div{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.count-symbol {
    font-size: 30px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    font-size: 30px;
    margin: 0 10px 10px 0;
    cursor: pointer,
}
.activity-over-rounds-evidence-ul{
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px;
    list-style: none;
}
.activity-over-rounds-evidence{
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
}
.activity-restart-button{
    padding: 10px;
    border: none;
    background-color: #000099;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: #fff;
    margin-right: 10px;
    border-radius: 7px;
}
.more-activity-button{
    padding: 10px;
    border: none;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: #242424;
    margin-right: 10px;
    border-radius: 7px;
}
.activity-game-over-data{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.common-activity-block-walls{
    padding: 10px;
}
.common-over-block-walls{
    padding: 0px 10px 10px 10px;
}


.number-mastermind-rounds-wrapper{
    display: grid;
    grid-template-columns: auto;
    gap: 10px 10px; 
}
.number-mastermind-rounds-block{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    padding: 10px;
}
.number-mustermind-rounds-record{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min-content, 1fr));
    gap: 10px 10px; 
    
}
.number-mustermind-rounds-blocks{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.number-mustermind-rounds-blocks p{
    margin: 10px 0px;
}


.equations-aligner-block-cover{
    display: block;
    margin: auto;
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px;   
}
.equations-block-box{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
}
.equations-block-box p{
    margin: 10px 0px;
}
.equations-block-box-input{
    padding: 10px;
    border-radius: 7px;
    border: 2px solid #ddd;
    margin-top: 5px;
}
.equation-p-tag{
    font-size: 20px;
}


.big-number-challenge-buttons-arrows{
    display: block;
}
.big-number-challenge-buttons-arrows button{
    margin: 0px 15px;
    padding: 5px 10px;
    background-color: #fff;
    color: #242526;
    border: 2px solid #b4b3b3;
    border-radius: 7px;
    font-weight: bold;
}
.big-number-challenge-buttons-arrows button:hover{
    background-color: #444444;
    color: #fff;
}
.appropriate-symbol-selector{
    margin-bottom: 10px;
}
.size-showdown-circles-box{
    background-color: #6d6c6c;
    border: 1px solid #242526;
}


@media only screen and (min-width:500px) {
    .number-mustermind-rounds-record{
        grid-template-columns: auto auto; 
    }
}
@media only screen and (min-width:600px) {
    .equations-aligner-block-cover{
        grid-template-columns: auto auto; 
    }
}
@media only screen and (min-width:768px) {
    .counting-activity-rounds-block{
        grid-template-columns: auto auto;
    } 
    .number-mastermind-rounds-wrapper{
        grid-template-columns: auto auto;
    }
    .activity-over-rounds-evidence-ul{
        grid-template-columns: auto auto; 
    }
}
@media only screen and (min-width:768px) {
    .multiple-q-tested-wall-container{
        padding: 0px 10%;
    }
}
@media only screen and (min-width:1024px) {
    .activity-wall-container{
        margin-left: 250px;
    }
    .running-activity-section{
        padding: 0px 10px 20px 25px;
    }
    .activity-page-side-aligner{
        margin-left: 250px;
        padding: 0px 20px;
    }
    .multiple-q-tested-wall-container{
        margin-left: 250px;
        max-width: 700px;
    }
    .tested-activity-wall-container{
        margin-left: 250px;
        padding: 0px 20px;
    }
    .multiple-choice-ul-block img{
        max-width: 80%;
    }
}