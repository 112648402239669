.admin-container-block{
    margin: 10px; 
    padding: 10px; 
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.admin-container-block button{
    padding: 5px;
    border-radius: 7px;
    border: 2px solid #ddd;
    background-color: #fff;
}


.pdf-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-modal-content {
    position: relative;
    width: 80%;
    height: 80%;
    background: white;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.pdf-viewer {
    flex: 1;
    width: 100%;
    border: none;
}

.pdf-close-modal-btn {
    position: absolute;
    bottom: 50%;
    right: 10px;
    background: red;
    color: white;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
}

.activity-behaviour-notice{
    color: red;
}

.view-pdf-btn {
    background: #ffcc00;
    color: #000;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin: 10px 0;
    position: fixed;
    right: 20px;
    bottom: 50%;
}

@media only screen and (min-width:768px) {
    .view-pdf-btn {
        right: 30px;
    }
}
@media only screen and (min-width:900px) {
    .view-pdf-btn {
        right: 50px;
    }
}