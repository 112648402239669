/* Communities.css */

.communities-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
    margin: 10px;
}
  
.community-card {
    /*border: 1px solid #ccc;*/
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform 0.2s ease-in-out;
}
  
.community-card:hover {
    transform: translateY(-5px);
}
  
.community-card-link {
    text-decoration: none;
    color: inherit;
    display: block;
}
  
.community-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}
  
.community-card-content {
    padding: 15px;
}
  
.community-card-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}
.articles-card-title {
    font-size: 1.5rem;
    padding: 0px 10px 10px 10px; 
    margin: 0px;
    text-decoration: underline;
}
.aricle-block-div{
    margin: 0px 0px;
    width: auto;
}
.aricle-block-div ul{
    padding-left: 20px;  
}
.aricle-block-div li{
    padding: 0px;  
    background-color: #eee;
}
.aricle-block-div label{
    color: grey;
    font-style: italic;
}
.publishing-date{
    color: grey;
}
.aricle-block-div p{
    text-align: justify; 
}
.aricle-block-div-hr{
    margin: 0px 30px; 
    display: flex;
    justify-content: center;
}
.article-name-tag-h1{
    font-size: 27px;
    text-decoration: underline;
    text-align: center;
    margin: 0px;
    padding: 15px;
}
.aricle-block-div-pad{
    margin: 0px 10px;
}
.read-more-link p{
    color: #242526;
    background-color: #fff;
    padding: 10px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 10px; 
}
.read-more-link p:hover{
    color: blue;
}
.articles-date{
    margin: 0px 0px 10px 10px; 
    text-decoration: none;
    color: grey; 
}


  
.community-card-description {
    font-size: 1rem;
    color: #666;
}
  
/* Styles for when the user is not logged in */
  
.not-logged-in-message {
    margin-top: 20px;
    font-size: 1.2rem;
}

.disclaimer-note-label-container{
    text-align: center;background-color: red;
}
.disclaimer-note-label{
    text-align: center;
    margin: 0px ;
    
}

.extra-class-section-blocks section{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.extra-class-section-blocks h2{
    font-size: 20px;
    margin: 10px 0px 0px 10px; 
    padding-top: 10px;
    text-decoration: underline;
    text-align: center;
}
.video-streaming-link-box{ 
    border-radius: 7px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
    padding: 0px 10px;
    margin-top: 10px;
}
.video-streaming-link-box p{
    margin: 0px;
} 
.main-class-collection-container{
    display: block; 
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px; 
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} 
.main-class-collection-container h3{
    font-size: 18px;
    margin: 0px;
    padding: 1px 0px 0px 0px;
}
.collection-item-links{
    border: 2px solid #ddd;
    border-radius: 7px;
    padding: 10px; 
}
.collection-item-links h4{
    margin: 0px;
    padding: 15px 0px;
}
.stream-link-block{
    border-radius: 5px;
    border: 2px solid #dde;
    background-color: #fff;
    color: #242526;
    padding: 10px;
}
.stream-link-block p{
    margin: 10px 0px; 
    
}
.stream-link-block p:hover{
    text-decoration: none;
}
.stream-link-block:hover{
    background-color: #000099;
    text-decoration: none;
    color: #fff;
}
.ap-post-block{
    margin-top: 10px;
    display: none;
}
.ap-post-block-dash{
    margin: 10px;
}
  

.submit-tool-post{
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}
.add-more-media{
    margin-bottom: 10px;
    background-color: #ddd;
    padding: 10px;
    border-radius: 7px;
    border: none;
}
.add-more-media-select{
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 7px;
}
.add-more-media-subject{
    margin-top: 10px;
}
.add-more-media-subject input{
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 7px;
}
.add-more-media-div input{
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 7px;
    margin-bottom: 10px;
}
.add-more-media-submit{
    background-color: #000099;
    border: none;
    color:#fff;
    padding: 10px;
    border-radius: 7px;
    margin-left: 10px;
}
.add-more-media-bottom-buttons{
    display: block;
    margin-top: 10px;   
}
.collection-item-links-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px; 
}
.logged-in-section{
    margin: 0px 10px;
}
.webinars-admin-add{
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 10px;
}
.webinars-admin-add input{
    padding: 10px;
    border-radius: 7px;
    margin-bottom: 10px;
    border: 2px solid #ddd;
}
.webinars-admin-add button{
    padding: 10px;
    border: none;
    border-radius: 7px;
    color: #fff;
    background-color: #000099;
}
.webinar-image-card-button{
    background-color: #000099;
    color: #fff;
    padding: 10px;
    border-radius: 7px;
    border: none;
}
.webinar-p-tag{
    margin: 0px;
    padding: 10px 0px;
}
.webinar-unique-image-card-text{
    text-align: center;
    background-color: #fff; 
    margin: 0px;
    padding: 10px 0px;
}


.modal-overlay {
    position: fixed; /* Make the modal overlay fixed to the viewport */
    top: 0;          /* Position it at the top */
    left: 0;         /* Position it on the left */
    right: 0;        /* Stretch it to the right */
    bottom: 0;       /* Stretch it to the bottom */
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;   /* Use flexbox to center the modal */
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    z-index: 1000;   /* Ensure it appears on top of other content */
  }
  
  .modal-content {
    background: white; /* Background color for the modal */
    padding: 20px;     /* Some padding around the content */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Add a shadow for depth */
    max-width: 800px; /* Limit the maximum width */
    width: 90%;       /* Width responsive to the screen */
    height: auto;
  }
  
  .modal-close {
    background: transparent; /* Transparent background for the close button */
    border: none;            /* No border */
    font-size: 18px;        /* Larger font size for the close button */
    cursor: pointer;         /* Pointer cursor for hover */
    position: absolute;      /* Position the close button */
    top: 10px;              /* 10px from the top */
    right: 10px;            /* 10px from the right */
  }
  


@media only screen and (min-width:460px) {
    .ap-post-block{
        display: block;
    }
}
@media only screen and (min-width:550px) {
    .ap-post-block{
        display: none;
    }
}
@media only screen and (min-width:640px) {
    .ap-post-block{
        display: block;
    }
    .aricle-block-div{
        margin: 0px 30px;
    }
}
@media only screen and (min-width:768px) {
    .ap-post-block{
        display: none;
    }
    .aricle-block-div{
        margin: 0px 60px;
    }
    .aricle-block-div-hr{
        margin: 0px 60px;  
    }
    .aricle-block-div-pad{
        margin: 0px 60px;
    }
}
@media only screen and (min-width:1200px) {
    .ap-post-block{
        display: block;
    }
    .aricle-block-div{
        margin: 0px 100px;
    }
    .aricle-block-div-hr{
        margin: 0px 120px;  
    }
    .aricle-block-div-pad{
        margin: 0px 80px;
    }
}


@media only screen and (min-width:768px) {

}