.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
  
.modal-content {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.modal-content button{
    margin: 10px 10px 0px 0px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    border: 2px solid #ddd;
}
.proceed-modal-button{
    background-color: #000099;
    color: #fff;
    border: 2px solid #000099;
    border-radius: 7px;
}
  
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
  
.close:hover {
    color: red;
}
  