.game-name-list-files{
  padding: 10px;
  background-color: #eee;
  /*height: 100vh;*/
  display: flex;
  justify-content: center;
  margin: 0px;
}
.game-name-list-files-box{
  width: 100%;
}
.terms-title{
  text-align: center;
  margin: 10px; 
  text-decoration: underline;
}
.terms-block{
  text-align: start;
  background-color: #fff;
  border-radius: 10px;
  color: #242526;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
.terms-block h3{
  margin-bottom: 0px;
  text-decoration: underline;
}
.terms-block p{
  margin-top: 0px;
  text-align: justify;
}
.terms-list{
  padding-left: 30px;
}
.terms-list li{
  text-align: justify;
  padding-bottom: 10px;
}
.page-colour-manager{
  padding: 0px 10px 10px 10px;
  background-color: #eee;
  border-radius: 15px; 
  width: auto;
  margin: 0px;
  /*height: 100vh;*/
}
.set-page-colour-manager{
  padding: 0px 10px 10px 10px;
  background-color: #eee;
  width: auto;
  margin: 0px;
  border-radius: 15px;
}
.my-study-container{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.game-name-list-files h1{
  text-decoration: underline; 
}
.subjects-by-Grade{
  padding: 10px;
  width: auto; /* Specify your desired width */
  margin-left: auto;
  margin-right: auto;
  /*height: 100vh;*/
}
.subjects-by-Grad-inside{
  display: block;
}
.page-title{
  text-align: center;
  color: #333;
}
.links-div-block{
  color: #333;
  background-color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.links-div-block:hover{
  text-decoration: none;
  background-color: #000099;
  color: #fff;
}


.link-color {
    color: #000099;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .modal-actions button {
    margin: 0 10px;
  }
  .modal-content ul {
    display: 'flex';
    flex-direction: 'column';
  }
  
  .modal-content ul li {
    display: 'block';
    margin-bottom: '10px';
  }



.flex-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 10px 10px; /* Adjust the gap between cards */
}
.my-subjects-text{
  color: #333;
  text-align: center;
}
.my-subjects-text:hover{
  text-decoration: none;
}
.my-subjects-text-inner{
  background-color: #fff;
  padding: 7px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #333; 
  height:fit-content;
}
.my-subjects-text-inner:hover{
  text-decoration: none; 
  background-color: #000099;
  color: #fff;
}
.subject-card {
  text-align: center;
} 

.subject-details{ 
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}
.subject-details p{
  margin: 5px 0px 0px 0px;
}
.subject-grade-title-tile{
  padding: 5px;
  font-weight: bold;
} 
.subject-search-input-container{
  justify-content: center;
  display: flex;
}
.subject-search-input{ 
  margin-bottom:20px ;
  padding: 10px;
  border-radius: 5px 0px 0px 5px; 
  border: none;
  background-color: #fff; 
}
.subject-search-input-icon{
  background-color: #fff;
  padding: 7.5px 7.5px 7.5px 7.5px;
  border-radius: 0px 5px 5px 0px;
}

.meant-to-align{
  display: flex;
  justify-content: center;
}
.align-container{
  width: 100%;
}
.school-name-pin-block{
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  max-width: 350px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.grade-level-subject{
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
}
.my-game-name-text{
  background-color: #fff;
  width: 100%;
  border: none;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #333;
}
.my-game-name-text:hover{
  background-color: #000099;
  color: #fff;
}

.dashboard-main-container{
  margin-left: auto;
  margin-right: auto;
  /*background-color: red;*/
  padding: 10px;
}

.story-p-tag{
  text-align: justify;
  text-justify: inter-word;
  width: auto;
  margin-top: 0px;
  padding: 0px 10px;
}
.story-wall-block{
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
}
.story-wall-block h2{
  font-size: 20px;
  margin: 10px 0px;
  text-align: center;
}
.story-wall-block h3{
  font-size: 18px;
  margin: 10px 0px;
}
.story-image-cover-block img{
  max-width: 100%;
  height: auto;
}
.story-div-image-text{
  display: grid;
  grid-template-columns: auto; 
  gap: 10px 10px;
}
.comprehension-questions{
  padding: 0px 10px;
}
.comprehension-questions h3{
  text-decoration: underline;
}
.comprehension-questions p{
  margin-bottom: 0px;
  font-weight: bold;
}
.school-name-input{
  margin-top: 10px;
}
.tasks-school-name-input{
  padding: 10px;
  border-radius: 7px;
  border: 2px solid #ddd;
  margin-bottom: 10px;
}


@media only screen and (min-width:550px) {
  .align-container{
    width: 90%;
  }
}
@media only screen and (min-width:600px) {
  .game-name-list-files-box{
    width: 70%;
  }
}
@media only screen and (min-width: 768px) {
  .align-container{
    width: 70%;
  }

  .flex-container {
    min-width: 350px;
  }
  .subjects-by-Grade{
    width: 70%; 
  }
  .dashboard-main-container{
    width: 70%;
  }
  .game-name-list-files-box{
    width: 70%;
  }
  .story-div-image-text{
    display: grid;
    grid-template-columns: auto auto; 
    gap: 10px 10px;
  }
}
@media only screen and (min-width: 1024px) {
  .dashboard-main-container{
    margin-left: 250px;
  }
  .page-colour-manager{
    margin-left: 250px;
    padding: 0px;
  }
  .set-page-colour-manager{
    background-color: #eee;
    margin-left: 250px;
    padding: 0px;
  }
  .align-container{
    width: 90%;
  }
  .subjects-by-Grade{
    margin-left: 250px;
    width: 70%; 
    padding-left: 3.5%;
  }
  .game-name-list-files{
    width: auto;
    margin-left: 250px;
  }
  .game-name-list-files-box{
    width: 90%;
  }
}