/* auth.css */
.under-construction-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    margin: 0px;
}
  
.under-construction-content {
    text-align: center;
}
  
.construction-image {
    max-width: 100%;
    height: auto;
}
.posts-profileimage{
    border: 2px solid #000099;
}


.landing-page-first-div{
    width: auto;
    height: auto;
    background-color: #000099;
    top: 0px;
}
.landing-page-first-div{
    width: 100%;
    background-color: #fff;
}
.landing-page-block-one-block{
    background-color: #fff;
    padding: 0px 10px 25px 10px;
}
.landing-page-block-one-block h1{
    margin-top: 0px;
}
.get-started-button{
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.landing-page-block-one-block-Link-cover{
    color: #fff;
    padding: 15px;
    border-radius: 7px;
    background-color: #000099;
    border: none;
    font-size: large;
}
.landing-page-block-one-aligner{
    display: block;
}
.landing-page-block-one-block img{
    width: 100%;
    height: auto;
    padding: 10px 0px 0px 0px;
}
.landing-page-block-one{
    text-align: center;
    margin: none;
}
.desktop-logo-container{
    display: none;
}
.desktop-logo{
    width: 90%;
    height: auto;
}
.landing-page-block-two-block{
    background-color: #000099;
    text-align: center;
    padding: 0px 15px;
    color: #fff;
    margin: 0px;
}
.landing-page-block-two-block-aligner{
    display: block;
    justify-content: none;
}
.landing-page-block-two-blockss{
    margin: 0px;
    width: auto;
    
}
.landing-page-block-two-block p{
    font-size: 17px;
    text-align: justify;
}
.landing-page-block-two-block h3{
    margin-top: 10px;
}
.landing-page-block-two-block h2{
    text-decoration: underline;
    font-weight: bold; 
    margin-top: -5px;
}
.landing-page-block-two-block hr{
    width: 50%;
    display: block;
}
.landing-icon-cont{
    padding: 10px;
    display: flex;
    justify-content: center;
}
.landing-page-block-two-icon{
    display: block;
    color: #fff; 
}


.landing-page-block-three-block{
    color: #242526;
    background-color: #fff;
    text-align: center;
    padding: 10px 15px 10px 15px;
    margin-top: -5px;
}
.section-three-landing-aligner{
    display: block;
}
.section-three-landing-aligner hr{
    display: block;
}
.section-three-landing-box{
    padding: 0px;
}
.section-three-landing-box p{
    text-align: justify;
}
.landing-page-block-three-block h2{
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 0px 15px 7px 15px;
    text-decoration: underline;
}
.landing-page-p-title{
    margin: 0px;
}
.section-three-landing{
    width: 100%;
    height: auto;
}


.landing-page-block-four-block{
    background-color: #fff;
    text-align: center;
    color: #242526; 
    padding: 10px 15px 10px 15px;
}
.landing-page-block-four-block hr{
    width: 50%;
}
.landing-page-block-four-block h2{
    margin: 0px; 
    padding: 0px 15px 15px 15px;
    margin-top: 0px;
}
.landing-testimonials{
    width: 50px;
    height: 50px;
}
.testimonial-block{
    border: 2px solid #000099;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
}
.testimonials-author{
    display: flex;
}
.testimonials-author p{
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: start;
}
.landing-testimonials{
    margin-right: 10px;
    border-radius: 50%;
}
.testimonial-author-name{
    font-weight: bold;
}



.landing-page-block-five-block{
    background-color: #000099;
    text-align: center;
    color: #fff;
    padding: 10px 15px 10px 15px;
    margin-top: -5px;
}
.about-us-section-aligner{
    display: block;
}
.about-us-section-box{
    display: block;
}
.about-us-section-box p{
    text-align: justify; 
}
.landing-page-block-five-block h2{
    margin-top: 0px;
    text-decoration: underline;
}
.landing-page-block-five-block hr{
    width: 50%;
}
.our-values-section-container{
    display: block;
}
.our-values-section{
    background-color: #000099;
    width: auto;
}
.testimonial-block-view{
    display: none;
}
.contact-us-links a{
    color: #242526;
    text-decoration: none;
    font-weight: bold;
}
.contact-us-links a:hover{
    text-decoration: none;
} 
.contact-us-links-p p{
    color: #fff;
}
.contact-us-links-p{
    background-color: #000099;
    color: #fff; 
    padding: 10px;
    border-radius: 5px; 
    margin: 10px;
}
.landing-page-p-title-contact{
    margin-bottom: 15px;
}



.landing-page-block-six-block{
    color: #242526;
    background-color: #fff;
    text-align: center;
    padding: 10px 15px 10px 15px;
    margin-top: -5px;
}
.landing-page-block-six-block h2{
    margin-top: 0px;
    text-decoration: underline;
}
.landing-page-social-media-links{
    color: #242526;
    padding-left: 5px;
    text-decoration: underline;
}



.landing-page-block-seven-block{
    color: #fff;
    background-color: #000099;
    text-align: center;
    padding: 10px 15px 10px 15px;
    margin-top: -5px;
}
.landing-page-block-seven-block h2{
    margin-top: 0px;
    text-decoration: underline;
    margin-bottom: 15px; 
}
.landing-page-block-seven-faq h2{
    margin-top: 0px;
    text-decoration: underline;
}
.landing-page-block-seven-faq{
    max-width: 650px; 
    margin-left: auto;  
    margin-right: auto;
}
#faq {
    margin: 0 auto; /* Center the FAQ section */
    background-color: #000099; /* Light background for contrast */
}

dl {
    margin: 0; /* Remove default margin */
}

dt {
    cursor: pointer; /* Change cursor to pointer for interaction */
    background-color: #fff; /* Primary color for questions */
    color: #242526; /* White text for contrast */
    padding: 10px; /* Padding for spacing */
    border-radius: 5px; /* Rounded corners */
    margin: 10px 0; /* Space between questions */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

dt:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

dd {
    padding: 10px 15px; /* Padding for answers */
    border-left: 4px solid #007bff; /* Left border for emphasis */
    background-color: #d8d8d8; /* Light background for answers */
    color: #333;
    margin-bottom: 10px; /* Space below each answer */
    display: none; /* Hide answers by default */
}

dd.show {
    display: block; /* Show answer when the class "show" is added */
}



.started-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.started-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}
  
.started-modal button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    background: #000099;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}
  
.started-modal button:hover {
    background: #0056b3;
}
  

.login-second-stage-agree{
    color: #0056b3;
    text-decoration: underline;
}




/* MenuToggle.css */
.menu-toggle-container {
    display: inline-block;
    position: relative;
    background-color: #fff;
    width: 100%;
}
  
.toggle-button {
    color: white;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    user-select: none;
    text-align: right;
    background-color: #333;
}
  
.menu-list {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
}
  
.menu-list.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
  
.menu-item {
    padding: 5px;
    cursor: pointer;
    margin: 10px;
    background-color: #fff;
    border-radius: 7px;
} 
.menu-item-links {
    color: #242526;
}
.menu-item:last-child {
    border-bottom: none;
} 
.menu-item:hover {
    background-color: #000099;
    color: #fff;
    text-decoration: none;
}
.menu-item-links:hover {
    text-decoration: none;
}
.login-signup-block{
    padding-top: 3px;
    border-top: 1px solid #fff;
}





/* DesktopMenu.css */
.desktop-menu {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    color: #333;
    font-family: Arial, sans-serif;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #fff;
    /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
  }
  
  .menu-left ul,
  .menu-right ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .menu-left li,
  .menu-right li {
    margin-right: 20px;
  }
  
  .menu-left li:last-child {
    margin-right: 0; /* No margin on the last item */
  }
  
  .menu-right li {
    margin-left: 15px; /* Space between login and signup */
  }
  
  .menu-left a,
  .menu-right a {
    color: #333;
    text-decoration: none;
    font-size: 16px;
  }

  .menu-left{
    margin-left: 15px;
  }
  
  .menu-left a:hover,
  .menu-right a:hover {
    color: #333; /* Lighter color on hover */
  }
  
  .menu-left a {
    text-transform: uppercase; /* Uppercase for left side menu items */
  }
  
  .menu-right a {
    font-weight: bold; /* Bold text for login and signup */
  }
  
  .menu-right a.signup {
    border-radius: 5px;
  }
  
  .menu-right a.signup:hover {
    background-color: white;
    color: #333;
  }
  



.not-logged-in-alt{ 
    width: 100%;
    
}  
.not-logged-in-alt-aligner{
    padding-bottom: 10px;
    
} 
.copy-text-block{
    background-color: red;
    padding: 10px;
    position: absolute;
    bottom: 0px; 
}



.unique-image-card-container-pad{
    padding: 0px;
}
.unique-image-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
    padding: 10px;
} 
/* Individual image card styling */
.unique-image-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform 0.3s;
    cursor: pointer;
} 
.unique-image-card:hover {
    transform: translateY(-5px);
} 
/* Image inside the card */
.unique-image-card-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    background-color: #fff;
} 
.meetings-label-text{
    background-color: #fff;
    text-align: center; 
    margin: 0px;
    opacity: 50%;
}
/* Text underneath the image */
.unique-image-card-text-container{
    min-height: 50px; 
    background-color: #fff;
}
.unique-image-card-text {
    padding: 0px;
    background-color: #fff;
    text-align: center;
    font-size: 16px;
    color: #333;
    margin: 0px;
} 
.unique-image-conference-text-container{
    background-color: #fff;
}
.unique-image-conference-text {
    padding: 0px;
    background-color: #fff;
    text-align: center;
    font-size: 16px;
    color: #333;
    margin: 0px;
} 
.unique-image-conference-text-caption{
    padding: 10px;
    background-color: #fff;
    text-align: center;
    font-size: 16px;
    color: #333;
    margin: 0px;
}
.unique-image-conference-poster-date{
    padding-top: 10px;
    background-color: #fff;
    text-align: center;
    font-size: 16px;
    color: #333;
    margin: 0px;
    text-decoration: underline;
}
/* Modal overlay when the image is clicked */
.unique-image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
} 
/* Modal content */
.unique-image-modal-content {
    position: relative;
    padding: 0px;
}  
.unique-image-modal-image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.unique-image-card-link-container{
    padding: 10px;
    background-color: #fff;
    text-align: center;
}




.ad-container {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
  
.ad-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}




@media only screen and (min-width:630px) {
    .landing-page-block-one-block{
        padding: 0px 180px 25px 180px;
    }
}  
@media only screen and (min-width:650px) {
    .construction-image {
        max-width: 70%; 
        height: auto;
    }
}
@media only screen and (min-width:768px) {
    .landing-page-block-one-block{
        padding: 0px 180px 25px 180px;
    }
    .landing-page-block-two-block{
        padding: 0px 150px;
    }
    .landing-page-block-three-block{
        padding: 10px 155px 10px 155px;
    }
    .landing-page-block-six-block{
        padding: 10px 155px 10px 155px;
    }
    .landing-page-block-five-block{
        padding: 10px 150px 10px 150px;
    }
    .not-logged-in-alt-aligner{
        padding: 0px 40px; 
    }
    .unique-image-modal-content {
        padding: 50px;
    }  
}
@media (max-width: 900px) {
    .desktop-menu {
      display: none; /* Hide the menu on smaller screens */
    }
}
@media only screen and (min-width:900px) {
    .construction-image {
        max-width: 40%;
        height: auto;
    }.landing-page-block-one-block{
        padding: 0px 210px 25px 210px;
    }
    .landing-page-block-two-block{
        padding: 0px 170px;
    }
    .landing-page-block-three-block{
        padding: 10px 175px 10px 175px; 
    }
    .landing-page-block-six-block{
        padding: 10px 175px 10px 175px; 
    }
    .landing-page-block-five-block{
        padding: 10px 170px 10px 170px;
    }
    .menu-toggle-container {
        display: none;
    }
}
@media only screen and (min-width:1024px) {
    .under-construction-container {
        margin-left: 250px;
    }
    .construction-image {
        max-width: 60%;
        height: auto;
    }
    .landing-page-block-one-block{
        padding: 0px 250px 25px 250px;
    }
    .unique-image-modal-content {
        padding: 100px;
    } 
    .unique-image-card-container-pad{
        padding: 0px 15px;
    }
}
@media only screen and (min-width:1100px) { 
    .landing-page-block-one-block{
        padding: 0px 300px 25px 300px;
    }
    .unique-image-modal-content {
        padding: 200px;
    }
}
@media only screen and (min-width:1200px) {
    .landing-page-block-one-aligner{
        display: flex;
        flex-direction: row-reverse; 
    }
    .landing-page-block-one{
        margin-top: 160px;
    }
    .landing-page-block-one-block{
        padding: 0px 220px 25px 220px; 
    }
    .landing-page-block-two-block-aligner{
        display: flex;
    }
    .landing-page-block-two-block hr{
        display: none;
    }
    .landing-page-block-two-blockss{
        margin: 0px 20px;
        width: 30%;
    }
    .section-three-landing-aligner{
        display: flex;
        justify-content: space-evenly;
    }
    .landing-page-block-three-block{
        padding: 10px 100px 10px 100px;
    }
    .landing-page-block-six-block{
        padding: 10px 100px 10px 100px;
    }
    .section-three-landing{
        width: 280px;
        height: auto;
    }
    .section-three-landing-box{
        width: 40%;;
    }
    .section-three-landing-aligner hr{
        display: none;
    }
    .about-us-section-aligner{
        display: flex;
        justify-content: space-evenly;
    }
    .about-us-section-box{
        padding: 20px;
        min-width: 272px; 
    }
    .our-values-section-container{
        display: flex;
        justify-content: center;
    }
    .our-values-section{
        max-width: 450px;
    }
    .desktop-logo-container{
        display: block;
    }
    .landing-page-block-one-block img{
        width: 60%;
        height: auto;
    }
    .unique-image-modal-content {
        padding: 300px;
    }
}