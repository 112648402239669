.site-control-colour{
    background-color: #eee; 
}
.admin-user-list{
    background-color: #fff;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.admin-user-list p{
    margin: 10px;
}
.name-and-surname{
    font-size: larger;
    font-weight: bold;
}
.status-change-button{
    display: flex;
}
.status-change-button select{
    border-radius: 5px;
}
.search-field-for-outside{
    padding: 10px;
    border-radius: 10px;
    margin: 10px 10px 0px 10px;
    /*border: 2px solid #ddd;*/
    border:none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.submit-int-form-block-container{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 10px;
}
.submit-int-form-block-container h3{
    margin: 10px 0px;
    text-decoration: underline;
}
.submit-int-form-block{
    display: block;
}
.submit-int-form-block input{
    padding: 10px;
    border-radius: 7px;
    border: solid 2px #ddd;
    margin-bottom: 10px;
}
.submit-int-form-block select{
    padding: 10px;
    border-radius: 7px;
    border: solid 2px #ddd;
    margin-bottom: 10px;
}
.submit-int-form-block button{
    padding: 10px;
    background-color: #000099;
    color: #fff;
    border-radius: 7px;
    border: none;
    margin-top: 10px;
}
.schools-list-block-sections{
    display: block;
}
.schools-list-block-sections-ul{
    list-style: none;
    border-radius: 10px;
}
.schools-list-block-sections-li{
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
}
.inst-closeup-box-container label{
    color: #8c8b8b;
}
.inst-closeup-box-container p{
    margin: 0px 0px 13px 0px; 
}
.search-box-for-schools{
    padding: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 7px;
}

.institutions-list-container{
    padding: 0px 10px;
    /*height: 100vh;*/
    border-radius: 15px 15px 0px 0px;
}
.institutions-list{
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px;
}
.institution-card{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.institution-card-Link-tag{
    color: #242526;
    display: block;
    text-align: center;
}
.institution-card-Link-tag:hover{
    text-decoration: none;
}
.institution-card-Link-tag-img-container{
    display: flex;
    justify-content: center;
}
.institution-card-Link-tag-img{
    padding: 5px;
    background-color: #000099;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    margin-right: 10px;
}
.institution-card h2{
    margin: 10px 0px;
    text-align: center;
}
.institution-card p{
    margin: 10px 0px;
}
.search-field-for-institutions{
    padding: 10px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
}
.institution-card-buttons{
    display: flex;
    justify-content: space-evenly;
    padding: 0;
}
.institution-card-buttons button{
    padding: 10px;
    border-radius: 7px;
    width: 45%;
}
.institution-card-subscribe{
    background-color: #000099;
    color: #fff;
    border: 2px solid #ddd;
}
.institution-card-email{
    background-color: #fff;
    border:2px solid #000099;
}
.managed-by-you{
    margin: 10px 0px;
    color: red;
}



.carousel-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: auto;
    overflow: hidden;
}
  
.carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
}
  
.carousel-slide {
    min-width: 100%;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
}
  
.carousel-slide img {
    max-width: 100%;
    height: auto;
}

.carousel-slide p{
    margin-bottom: 0px;
}
  
.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 7px 10px;
    cursor: pointer;
    border-radius: 50%;
}
  
.carousel-control.prev {
    left: 0;
}
  
.carousel-control.next {
    right: 0;
}
.about-text-blob-container{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    
}
.about-text-blob{
    width: 80%;
    text-align: center;
    line-height: 1.7;
}
.about-achievements-list-block{
    display: flex;
    justify-content: center;
}
.about-achievements-list-block ul{
    width: 65%;
    list-style: none;
}
.about-achievements-list-block li{
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}
.achievement-h2{
    text-align: center;
    margin-top: 0px;
}

.menu-div-test-block{
    background-color: red;
}
  
.form-group{
    margin-bottom: 10px;
}
.form-group input{
    padding: 5px;
    border-radius: 5px;
    border: 2px solid #ddd;
}
.btn-submit{
    margin: 10px 0px;
    background-color: #000099;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    border: none;
}
.ad-post-container{
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.ad-post-pad{
    border: 2px solid #ddd;
    max-width: 300px; 
    padding: 10px;
    border-radius: 7px;
}
.ad-media-file{
    width: 99%;
    height: auto;
    border-radius: 10px;
    margin-top: 3px;
}
.ad-learn-button{
    background-color: #fff;
    margin: 0px;
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
}
.ad-learn-button a{
    background-color: #000099;
    padding: 7px;
    border-radius: 7px;
}



@media only screen and (min-width:768px) {
    .institutions-list{
        display: grid;
        grid-template-columns: 50% 48.5%;
        gap: 10px 10px;
    } 
    .institutions-list-container{
        padding: 0px 30px;
    }
    
}
@media only screen and (min-width:900px) {
    .institution-card-buttons{
        padding: 0px 20px;
    }
    .about-achievements-list-block ul{
        width: 40%;
    }
}
@media only screen and (min-width:1024px) {
    .institution-card-buttons{
        padding: 0px 10%;
    }
    .about-text-blob{
        width: 70%;
        text-align: center;
        line-height: 1.7;
    }
}
@media only screen and (min-width:1100px) {
    .about-text-blob{
        width: 62%;
        text-align: center;
        line-height: 1.7;
    }
}