.giveback-container {
    max-width: 800px; /* Optional: limit the width of the container */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Add some padding */
}

.section {
    border: 1px solid #ddd; /* Add border to each section */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Inner padding */
    margin-bottom: 20px; /* Space between sections */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    background-color: #f9f9f9; /* Light background color */
}

.public-section {
    background-color: #e3f2fd; /* Light blue for public section */
}

.admin-section {
    background-color: #ffe0b2; /* Light orange for admin section */
}
.admin-button-switch-point button{
    padding: 10px;
    border-radius: 7px;
    border: none;
    background-color: #000099;
    color: #fff; 
}
.submit-statement-form{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 10px 0px 20px 0px;
    border-radius: 10px;
    padding: 15px;
}
.submit-statement-form textarea{
    min-height: 150px;
    max-width: 450px;
    border: 2px solid #ddd;
    border-radius: 10px;
    margin-bottom: 5px;
}
.submit-statement-form input{
    margin-bottom: 10px;
}
.submit-statement-form button{
    padding: 10px;
    border-radius: 7px;
    border: none;
    background-color: #000099;
    color: #fff;
    margin-top: 10px;
}
.Give-back-block-list-ul{
    list-style: none;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: auto;
}
.Give-back-block-list-ul li{
    background-color: #fff;
    border-radius: 10px;
    margin: 10px 0px 10px 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 15px; 
    text-align: justify;
}
.give-back-block-list-ul-container{
    margin: 0px;
}


.individual-questions-block{
    border: 2px solid #ddd;
    padding:10px;
    border-radius: 7px;
    margin-bottom: 10px;
}

 
@media only screen and (min-width:768px) {
    .Give-back-block-list-ul{
        margin: 0px 100px;
    }
}
@media only screen and (min-width:1200px) {
    .Give-back-block-list-ul{
        margin: 0px 120px;
    }
}