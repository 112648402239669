.user-link-sections-blocks{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    border-radius: 10px;
    padding-top: 1px;
    margin: 10px; 
}
.userlinks-page{
    width: 100%;
    background-color: #eee;
    margin: 0px;
    border-radius: 15px;
}
.userlink-title-name{
    text-align: center;
    background-color: #fff;
    margin: 0px;
    padding: 15px 0px;
    font-size: 25px;
    text-decoration: underline;
}

.user-link-section-content-container{
    display: block;
}
.user-link-section-content{
    background-color: #eee;
}

.tracking-requests-list-block{
    display: grid;
    grid-template-columns: auto; 
    gap: 10px 10px; /* Adjust the gap between cards */
}
.tracking-requests-list-block button{
    background-color: #fff;
    padding: 10px;
    border-radius: 7px;
    margin-right: 10px;
    margin-top: 10px;
    border: 2px solid #7d7d7d;

}
.tracking-requests-li{
    padding: 10px 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    list-style: none;
}
.tracking-requests-li img{
    height: 170px;
    width: 170px;
    border-radius: 50%;
    margin-right: 10px;
}
.tracking-requests-list-none{
    background-color: #fff;
    margin: 10px 0px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    max-width: 500px;
}
.tracking-requests-list-none p{
    color: #242526;
    margin: 0px;
    padding: 0px;
} 


.followers-section-list{
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.followers-section-list-blocks{
    /*background-color: #fff;*/
    padding: 10px;
    border-radius: 10px;
    margin-right: 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    list-style: none;
}
.user-link{
    display: flex;
    color: black;
}
.user-link:hover{
    text-decoration: none;
}
.followers-section-list-blocks p{
    margin: 0px 0px 10px 0px;
}
.userlink-followers-profile{
    height: 160px;
    width: 160px;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px solid #000099;
}
.userlink-followers-name-surname{
    font-weight: bold;
}


@media only screen and (min-width:490px) {
    .userlink-followers-profile{
        height: 170px;
        width: 170px;
        border-radius: 50%;
        margin-right: 10px;
    }
}
@media only screen and (min-width:768px) {
    .tracking-requests-list-block{
        grid-template-columns: repeat(auto-fill, minmax(min-content, calc(50% - 10px)));
    }
    .followers-section-list-blocks{
        width: auto; 
    }
}
@media only screen and (min-width:1024px) {
    .userlinks-page{
        margin-left: 250px;
        width: auto;
    }
}