.sidebar {
  position: fixed;
  top: 0;
  left: -250px; /* Start the sidebar off-screen */
  width: 250px;
  height: 100%;
  background-color: #eee;
  transition: left 0.3s ease; /* Add transition for smooth animation */
  z-index: 999; /* Ensure the sidebar stays above the overlay */
  border-radius: 0px 10px 10px 0px;
  overflow: auto;
  border: none;
}
.navbar-support-pad-box{
  margin-top:0px;
  
}
.sidebar.open {
  left: 0; /* Move the sidebar into view when it's open */
}

.sidenav-logout-block{
  color: #333;
  display: flex;
  background-color: #fff;
  margin: 10px 10px 20px 10px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.sidenav-logout-block:hover{
  color: #fff;
  display: flex;
  background-color: #333;
  text-decoration: none;
}

.sidenav-link-block{
  color: #333;
  display: flex;
  background-color: #fff;
  margin: 10px 10px 10px 10px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.sidenav-link-block:hover{
  color: #fff;
  display: flex;
  background-color: #000099;
  text-decoration: none;
}
.sidenav-icons{
  margin-right: 15px;
}
.notifications-name-tag{
  margin: 0px 0px 0px 10px;
}


.navbar-icons-container {
  display: inline-block;
  width: 30px;
  text-align: center;
}

.navbar-icons-container p {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.sidebar-toggle-button { 
  position: fixed;
  top: 50%;
  left: 0px;
  z-index: 999; /* Ensure the button stays above the sidebar and overlay */
  background: transparent;
  border: none;
  font-size: 1.5em;
  color: #333;
  cursor: pointer;
  background-color: #eee;
  padding: 10px;
  border-radius: 0px 10px 10px 0px;
  border: 2px solid #333;
  opacity: 50%;
}
.open-men-icon-bars{
  
}

.navbar-div-beyond-desktop-container{
  height: 100%;
  bottom: 0px; 
  display: none; 
  position: fixed;
}
.navbar-div-beyond-desktop-middle{
  position: absolute;
  background-color: #fff;
  width: 250px;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  overflow: auto;
}
.navbar-div-beyond-desktop{
  
  display: none;
  cursor: pointer;
}
  
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: none; /* Initially hidden */
  z-index: 998; /* Ensure the overlay stays behind the sidebar */
  cursor: pointer; /* Add cursor pointer to indicate clickability */
}

.overlay.active {
  display: block; /* Show the overlay when active */
}

.potential-side-menu{
  
}
.navbar-divider-engage{
  text-align: center;
  color: gray;
}





.side-nav {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: -250px;
  background-color: #fff;
  transition: left 0.3s ease;
  z-index: 100;
}

.side-nav.open {
  left: 0;
}
.toggle-btn-container{
  position: fixed;
  top: 50%;
}
.toggle-btn {
  position: absolute;
  top: 50%;
  left: 250px; /* Adjust the left position to be just outside the side-nav */
  z-index: 20;
  background-color: red;
  padding: 7px 7px 7px 0px;
  border-radius: 0px 15px 15px 0px;
  opacity: 30%;
}

/*
nav {
  background-color: #fff;
  right: 0px;
  top: 0px;
  bottom: 0px;
  padding-top: 50px;
  border: solid 2px #9a9a9a;
  border-radius: 10px 0px 0px  10px; 
  height: 100%;
  z-index: 10;
  overflow: auto;
  
}
*/

.navbar-ol-list-container {
  list-style: none;
  margin: 0px 10px 0px 10px;
  padding: 0;
}

li {
  margin-right: 10px;
}

.navbar-content-link{
  background-color: #000099;
  display: flex;
  margin: 10px 0px 10px 0px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}
.navbar-content-link:hover{
  background-color: #9a9a9a;
  color: #333;
  text-decoration: none;
}
.navbar-content-link p{
  margin: 0px 0px 0px 5px;
}
.navbar-icons{
  width: 30px;
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  text-decoration: underline;
}
.user-info-container{
  margin-top: 20px;
  overflow: auto;
}
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center; /* Center text horizontally */
  margin-bottom: 10px;
}
.user-profile-picture {
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  border-radius: 50%; /* Makes the image round */
  object-fit: cover; /* Ensures the image covers the entire area */
  margin-bottom: 10px; /* Adds some space below the image */
  border: 2px solid #000099;
}
.user-info p{
  margin: 0px;
}
.navbar-account-name-tag{
  opacity: 75%;
}
.navbar-names-tag{
  font-weight: bold;
}
.navbar-logout-button{
  width: 100%;
  padding: 10px 0px;
  background-color: #fff;
  border-radius: 5px;
}



.notifications-container-title{
  text-align: center;
}
.mark-all-as-read{
  border: none;
  border-radius: 5px;
  display: none;
}
.notification-window {
  position: fixed;
  top: 0;
  right: -310px; /* Initially hidden */
  width: 300px;
  height: 100%;
  background-color: #d3d3d3;
  z-index: 15;
  transition: right 0.3s ease;
  border: 2px solid #d3d3d3;
  border-radius: 10px 0px 0px 10px;
  overflow: auto;
}

.notification-window.open {
  right: 0; /* Visible when open */
}

.close-notification {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
.navbar-notification-button-container{
  margin-right: 10px;
}
.navbar-notification-button{
  width: 100%;
  padding: 12px 0px 12px 10px;
  background-color: #000099;
  border-radius: 5px;
  display: flex;
  border: none;
}
.navbar-notification-button p{
  margin: 0px;
  color: #fff;
  font-size: medium; 
  margin-left: 5px;
}
.Notification-list{
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: auto;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}
.Notification-list-block{
  border-radius: 10px;
}
.notifications-list-ul{
  list-style: none;
  border-radius: 5px;
  padding: 0px;
  width: auto;
  margin-left: 10px;
}
.notification-count{
  background-color: red;
  font-size: 12px;
  padding: 0px 4px 0px 4px;
  border-radius: 20px;
  position: absolute;
  left: 45px;
  color: #fff;
}
.notification-date{
  margin: 0px;
  font-size: 12px;
  opacity: 75%;
}
.notification-link{
  color: #333;
}
.notification-link:hover{
  text-decoration: none;
}

.notifications-buttons{
  display: flex;
  justify-content: center;
}
.notifications-buttons button{
  padding: 10px;
  margin: 0px 10px 10px 10px;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


@media only screen and (min-width: 1024px) {
  .navbar-support-pad-box{
    margin-top:60px;
  }
  .sidebar { 
    left: 0; /* Show the sidebar by setting left to 0 */
    /**/background-color: #fff;
    border: 2px solid #333;
  }
  .sidebar-toggle-button { 
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .sidebar { 
    display: none;
  }
  .navbar-div-beyond-desktop-container{
    display: block;  
  }
}